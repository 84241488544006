import {AuthService} from './auth-service';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/***
 * This service is used to ensure nothing is displayed when user isn't logged.
 */
export class AuthGuardService {
  /***
   * Constructor
   * @param authService Service used to reach the server. This one is used to log in or log out from the server.
   * @param router Angular service letting modify manually the route used in the web app.
   */
  constructor(private authService: AuthService,  private router: Router) { }

  /**
   * Method used to ensure a route can be reached (user has to be logged in)
   * @param route The route to check
   * @param state The state of the route
   */
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean |
    UrlTree> |
    Promise<boolean |
      UrlTree> | boolean |
    UrlTree {
    return this.authService.isLogged().then(
      (response: {errorMessage: string, status: number}) => {
        if (response.errorMessage) {
          // redirect to SignIn page because not logged
          this.router.navigate(['/SignIn']);
          this.authService.emitIsLogged(false);
          return false;
        }
        // Allow displaying info when user is logged
        else if (response.status === 200){
          this.authService.emitIsLogged(true);
          return true;
        }
      },
      // In the case there was an error, avoid displaying anything and go to SignIn page
      (error) => {
        console.log('Error happened: ', error);
        this.router.navigate(['/SignIn']);
        this.authService.emitIsLogged(false);
        return false;
      }
    );
  }
}
