import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TaskService} from '../service/task-service';
import {ActivatedRoute, Params} from '@angular/router';
import {StringService} from "../service/string.service";

@Component({
  selector: 'app-task-builder',
  templateUrl: './task-builder.component.html',
  styleUrls: ['./task-builder.component.css']
})

/***
 * This component is used to create or edit a task
 */
export class TaskBuilderComponent implements OnInit {
  // Form builder used to generate the reactive form
  taskBuilderForm: FormGroup;
  // Error message displayed when there is one, in a red box
  errorMessage: string;
  // Success message displayed when query return a message. In a blue box
  message: string;
  // Boolean used to know if the reactive form has been initialized or not.
  initialized: boolean;
  // Id of the task to edit. undefined if it's a new one
  taskId: number;

  /***
   * Constructor
   * @param formBuilder : form builder used to describe the task
   * @param taskService: service used to interact with task in DB
   * @param route: service used to get route parameter
   */
  constructor(private formBuilder: FormBuilder, private taskService: TaskService, private route: ActivatedRoute) {
    // check route param
    this.route.params.subscribe( (params: Params) => {
      this.taskId = params.taskId;

      // get the selected task if its ID is define. This will let you edit it
      if (this.taskId !== undefined) {
        this.taskService.getTask(this.taskId).then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.taskBuilderForm.setValue({
              id: response.task.id,
              barcode: response.task.barCodeNumber,
              description: response.task.taskDescription,
              type: response.task.type,
              duration: response.task.duration,
            });
          } else {
            this.errorMessage = response.message;
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Method that will initialize the form.
   */
  initForm() {
    this.taskBuilderForm = this.formBuilder.group(
      {
        id: [{value: '', disabled: this.taskId !== undefined}, [Validators.required,
          Validators.pattern('[0-9]+')]],
        barcode: ['', [Validators.required,
          Validators.pattern('[0-9]*'),
          Validators.maxLength(10),
          Validators.minLength(10)]],
        description: ['', Validators.required],
        type: ['', Validators.required],
        duration: ['', [Validators.required,
          Validators.pattern('[0-9]+')]],
      });

    this.initialized = true;
  }

  // convenience getter for easy access to form fields
  get controls() { return this.taskBuilderForm.controls; }

  /**
   * Method called when you create a task. It will send task properties to the server through the taskService.
   */
  createTask() {
    // Check if form is valid.
    if (this.taskBuilderForm.invalid) {
      this.errorMessage = 'fields are not properly filled';
      return;
    }
    // Get form fields values
    const id = this.taskBuilderForm.get('id').value;
    const barcode = this.taskBuilderForm.get('barcode').value;
    const description = StringService.removeQuote(this.taskBuilderForm.get('description').value);
    const type = StringService.removeQuote(this.taskBuilderForm.get('type').value);
    const duration = this.taskBuilderForm.get('duration').value;

    // create the task if in create mode
    if (this.taskId === undefined) {
      this.taskService.createTask(id, barcode, description, type, duration).then((answer: {errorMessage: string, message: string}) => {
        this.errorMessage = answer.errorMessage;
        this.message = answer.message;

        this.updateListOfTask();
      });
    } else {
      this.taskService.updateTask(id, barcode, description, type, duration).then((answer: {errorMessage: string, message: string}) => {
        this.errorMessage = answer.errorMessage;
        this.message = answer.message;

        this.updateListOfTask();
      });
    }
  }

  /***
   * Update ther list of subtask everywhere
   */
  updateListOfTask() {
    this.taskService.getTasks();
  }
}
