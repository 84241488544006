import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SubTaskService} from '../service/sub-task.service';
import {StringService} from "../service/string.service";

@Component({
  selector: 'app-sub-task-builder',
  templateUrl: './sub-task-builder.component.html',
  styleUrls: ['./sub-task-builder.component.css']
})
/***
 * This component is used to create or edit a subtask
 */
export class SubTaskBuilderComponent implements OnInit {
  // Form builder used to generate the reactive form
  subTaskBuilderForm: FormGroup;
  // Error message displayed when there is one, in a red box
  errorMessage: string;
  // Success message displayed when query return a message. In a blue box
  message: string;
  // Boolean used to know if the reactive form has been initialized or not.
  initialized: boolean;
  // id to the subtask to edit. undefined when it's a new subtask
  subTaskId: number;

  /***
   * Constructor
   * @param formBuilder : form builder used to describe the task
   * @param subTaskService: service used to interact with subtask in DB
   * @param route: service used to get route parameter
   */
  constructor(private formBuilder: FormBuilder, private subTaskService: SubTaskService, private route: ActivatedRoute, private router: Router) {
    // check route param
    this.route.params.subscribe( (params: Params) => {
      this.subTaskId = params.taskId;
      // get the selected subtask if its ID is define. This will let you edit it
      if (this.subTaskId !== undefined) {
        this.subTaskService.getSubTasks(this.subTaskId).then((response) => {
          if (response.status === 200) {
            this.subTaskBuilderForm.setValue({
              id: response.subTask.id,
              description: response.subTask.description,
            });
          } else {
            this.errorMessage = response.message;
          }
        });
      }
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Method that will initialize the form.
   */
  initForm() {
    this.subTaskBuilderForm = this.formBuilder.group(
      {
        id: [{value: '', disabled: this.subTaskId !== undefined},
          [Validators.required, Validators.pattern('[0-9]+')]],
        description: ['', Validators.required]
      });

    this.initialized = true;
  }

  // convenience getter for easy access to form fields
  get controls() { return this.subTaskBuilderForm.controls; }

  /**
   * Method called when you create a subtask. It will send subtask properties to the server through the subTaskService.
   */
  createSubTask() {
    if (this.subTaskBuilderForm.invalid) {
      this.errorMessage = 'fields are not properly filled';
      return;
    }

    const id = this.subTaskBuilderForm.get('id').value;
    const description = StringService.removeQuote(this.subTaskBuilderForm.get('description').value);

    // create the task if in create mode
    if (this.subTaskId === undefined) {
      this.subTaskService.createSubTask(id, description).then((answer: {errorMessage: string, message: string}) => {
        this.errorMessage = answer.errorMessage;
        this.message = answer.message;

        this.updateListOfSubTask();
      });
    } else {
      this.subTaskService.updateSubTask(id, description).then((answer: {errorMessage: string, message: string}) => {
        this.errorMessage = answer.errorMessage;
        this.message = answer.message;

        this.updateListOfSubTask();
      });
    }
  }

  /***
   * Update ther list of subtask everywhere
   */
  updateListOfSubTask() {
    this.subTaskService.getAllSubTasks();
  }

  /***
   * This will delete current subtask, then navigate to /
   */
  deleteSubTask() {

    this.subTaskService.deleteSubTask(this.subTaskId).then((answer: {errorMessage: string, message: string}) => {
      if(answer.errorMessage) {
        this.errorMessage = answer.errorMessage;
      }
      else {
        this.updateListOfSubTask();
        this.router.navigate(['/Home']);
      }
    });
  }
}
