import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/***
 * This service is used to interact with server login part. This will be the one responsible for the login, logout
 */
export class AuthService {
  // root url
  rootURL = '/api';
  canCreateSubject = new Subject<boolean>();
  isLoggedSubject = new Subject<boolean>();

  /**
   * Constructor
   * @param httpClient The angular service used to reach server.
   * @param router The angular service used to interact with routes.
   */
  constructor(private httpClient: HttpClient, private router: Router) { }

  /**
   * Method used to check if the user is logged.
   */
  isLogged(): Promise<object> {
    return this.httpClient.get(
      this.rootURL + '/isLogged',
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }
      }
    ).toPromise();
  }

  /**
   * Method used to log in to the server given a login and a password
   * @param username The login
   * @param password The password
   */
  login(username: string, password: string) {
    this.httpClient.post(
      this.rootURL + '/Login',
      {
        username,
        password
      },
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }
      }
    ).subscribe(
      (response: {errorMessage: string}) => {
        if (response.errorMessage) {
          this.emitIsLogged(false);
          this.router.navigate(['/LoginFailure']);
        }
        else {
          this.emitIsLogged(true);
          this.router.navigate(['/Home']);
        }
      },
      (error) => {
        console.log('Error happened: ', error);
        this.emitIsLogged(false);
        this.router.navigate(['/LoginFailure']);
      }
    );
  }

  /**
   * Used to broadcast the new given value
   * @param isLogged The new given value for is logged
   */
  public emitIsLogged(isLogged: boolean) {
    this.isLoggedSubject.next(isLogged);
  }

  /**
   * Used to logout from server
   */
  logout() {
    this.httpClient.get(
      this.rootURL + '/Logout',
      {
        headers: {
          'Content-type' : 'application/json',
          'Accept': 'application/json'
        }
      }
    ).subscribe(
      (response: {errorMessage: string}) => {
        // if(response.error) {
        //   this.router.navigate(['/']);
        console.log('logout');
        this.emitIsLogged(false);
        this.router.navigate(['/SignIn']);
      },

      (error) => {
        console.log('Erreur ! : ' + error);
        this.emitIsLogged(false);
        this.router.navigate(['/SignIn']);
      });
  }
}
