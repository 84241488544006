import {Component, OnDestroy, OnInit} from '@angular/core';
import {TaskObjectService} from '../service/task-object.service';
import {ActivatedRoute, Params} from '@angular/router';
import {TaskObject} from '../model/taskObject';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-remove-object',
  templateUrl: './add-remove-object.component.html',
  styleUrls: ['./add-remove-object.component.css']
})
/***
 * Component used to add or remove objects from task or object.
 */
export class AddRemoveObjectComponent implements OnInit, OnDestroy {

  // The task object to describe
  taskObjects: TaskObject[];
  // All task objects available
  allTaskObjects: TaskObject[];
  // the id of taskObject
  taskObjectId: number;
  // The listener to task objets from the current one
  taskObjectsSubscription = new Subscription();
  // The listener to all task objets
  allTaskObjectsSubscription = new Subscription();
  // true if it's a task, false if it's an object
  isTask: boolean;
  // Message to display
  message: string;
  // Error message to display
  errorMessage: string;

  /***
   * Constructor
   * @param taskObjectService service used to access DB objects
   * @param route service used to check route params
   */
  constructor(private taskObjectService: TaskObjectService, private route: ActivatedRoute) { }

  /***
   * Done once on init.
   */
  ngOnInit(): void {
    // check route params.
    this.route.params.subscribe( (params: Params) => {
      this.taskObjectId = params.taskId;

      // listen to list of object to display
      this.taskObjectsSubscription = this.taskObjectService.taskObjectsSubject.subscribe((taskObjects: TaskObject[]) => {
        this.taskObjects = taskObjects;

        this.allTaskObjects = this.allTaskObjects.filter((taskObjectToFilter: TaskObject) => {

          return this.taskObjects.map<number>((taskObject: TaskObject) => {
            return taskObject.id;
          }).indexOf(taskObjectToFilter.id) === -1;

        });

        this.sort();

      });

      // listen to list of all object to display
      this.allTaskObjectsSubscription = this.taskObjectService.allTaskObjectsSubject.subscribe((allTaskObjects: TaskObject[]) => {
        this.allTaskObjects = allTaskObjects;
      });

      this.taskObjectService.getAllTaskObjects();

      // If a task id is provided, then displays objects of task
      if (this.taskObjectId !== undefined) {
        this.taskObjectService.getTaskObjectsOfTask(this.taskObjectId);
        this.isTask = true;
      }
      // If a task object id is provided, then displays objects of object
      else if (params.taskObjectId !== undefined) {
        this.taskObjectId = params.taskObjectId;
        this.taskObjectService.getTaskObjectsOfObject(this.taskObjectId);
        this.isTask = false;
      }
    });
  }

  /***
   * Will add given object to current task or object
   * @param taskObject object to add
   */
  addObject(taskObject: TaskObject) {
    this.allTaskObjects = this.allTaskObjects.filter((taskObjectToFilter: TaskObject) => {
      return taskObjectToFilter.id !== taskObject.id;
    });

    this.taskObjects.push(taskObject);

    this.sort();
  }

  /***
   * Will remove given object from current task or object
   * @param taskObject object to remove
   */
  removeObject(taskObject: TaskObject) {
    this.taskObjects = this.taskObjects.filter((taskObjectToFilter: TaskObject) => {
      return taskObjectToFilter.id !== taskObject.id;
    });

    this.allTaskObjects.push(taskObject);

    this.sort();
  }

  /***
   * Used to sort the list of object by ids
   */
  sort() {
    this.message = undefined;
    this.taskObjects.sort((taskObject1: TaskObject, taskObject2: TaskObject) => {
      return taskObject1.id - taskObject2.id;
    });

    this.allTaskObjects.sort((taskObject1: TaskObject, taskObject2: TaskObject) => {
      return taskObject1.id - taskObject2.id;
    });
  }

  /***
   * Will send the changes to the server.
   */
  updateTaskObjectList() {
    const listToSend: {id1: number, id2: number}[] = new Array<{id1: number, id2: number}>();

    this.taskObjects.forEach((taskObject: TaskObject) => {
      listToSend.push({
        id1: this.taskObjectId,
        id2: taskObject.id
      });
    });
// console.log(listToSend);
    if (!this.isTask) {
      this.taskObjectService.associateObjectsToObject(listToSend)
        .then((response: {status: number, message: string, errorMessage: string}) => {
        this.message = response.message;
        this.errorMessage = response.errorMessage;
      });
    }
    else {
      this.taskObjectService.associateObjectsToTask(listToSend)
        .then((response: {status: number, message: string, errorMessage: string}) => {
          this.message = response.message;
          this.errorMessage = response.errorMessage;
        });
    }
  }

  ngOnDestroy(): void {
    this.allTaskObjectsSubscription.unsubscribe();
    this.taskObjectsSubscription.unsubscribe();
  }
}
