import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SubTaskService} from '../service/sub-task.service';
import {TaskLink} from '../model/task-link';

@Component({
  selector: 'app-all-sub-task-displayer',
  templateUrl: './all-sub-task-displayer.component.html',
  styleUrls: ['./all-sub-task-displayer.component.css']
})
/***
 * This component is used to display a list of sub tasks
 */
export class AllSubTaskDisplayerComponent implements OnInit, OnDestroy {

  // the task id
  @Input()
  taskId: number;
  // List of subTasks
  subTasks: TaskLink[];
  // Subscription to the list of subTasks
  subTaskSubscription = new Subscription();

  /***
   * Constructor
   * @param subTaskService Service that let interact with sub task data.
   */
  constructor(private subTaskService: SubTaskService) {
    this.subTasks = new Array<TaskLink>();

    // Listen to sub-tasks list changes
    this.subTaskSubscription = subTaskService.taskLinksSubject.subscribe((subTasks: Map<number, TaskLink[]>) => {
      this.subTasks = subTasks.get(this.taskId);
    });
  }

  ngOnInit() {
    // Get the list of subtasks for given task id.
    this.subTaskService.GetTaskLinksOfTask(this.taskId);
  }

  ngOnDestroy(): void {
    this.subTaskSubscription.unsubscribe();
  }
}
