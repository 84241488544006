import {Component, Input, OnDestroy} from '@angular/core';
import {SubTaskService} from '../service/sub-task.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TaskObject} from '../model/taskObject';
import {TaskObjectService} from '../service/task-object.service';
import {TaskLink} from '../model/task-link';

@Component({
  selector: 'app-sub-task-follower',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})

/***
 * Component used to let user follow a training.
 */
export class TrainingComponent implements OnDestroy{

  // the task ID
  @Input()
  taskId: number;
  // id of current task
  currentSubTask: number;
  // List of subTasks
  subTasks: TaskLink[];
  // Subscription to the list of subTasks
  subTaskSubscription = new Subscription();
  // table that let convert id to index
  idToIndex: number[];
    // list of available object
  taskObjects: TaskObject[];
  // Subscription to the list of subTasks
  taskObjectSubscription = new Subscription();

  /***
   *  constructor
   * @param subTaskService service used to interact with subtask and task links on server.
   * @param taskObjectService used to interact with taskObjects on server
   * @param route service used to get the route param
   * @param router service use to navigate to a route
   */
  constructor(private subTaskService: SubTaskService,
              private taskObjectService: TaskObjectService,
              private route: ActivatedRoute,
              private router: Router) {
    this.route.params.subscribe( (params: Params) => {
      this.taskId = params.taskId;

      // console.log(this.taskId);
      subTaskService.GetTaskLinksOfTask(this.taskId);
      this.taskObjectService.getTaskObjectsOfTask(this.taskId);
    });

    this.subTasks = new Array<TaskLink>();

    this.subTaskSubscription = subTaskService.taskLinksSubject.subscribe((subTasks: Map<number, TaskLink[]>) => {
      this.subTasks = subTasks.get(this.taskId);
      if(this.subTasks.length > 0) {
        this.currentSubTask = this.subTasks[0].currentSubtask;
        this.idToIndex = new Array<number>();
        for (let i = 0; i < this.subTasks.length; i++) {
          this.idToIndex[this.subTasks[i].currentSubtask] = i;
        }
      }
    });

    this.taskObjectSubscription = this.taskObjectService.taskObjectsSubject.subscribe((taskObjects: TaskObject[]) => {
      this.taskObjects = taskObjects;
    });
  }

  /***
   * Hadle what happen when you click on the buttons of the training
   * @param nextTaskID id of the next subtask to show
   */
  handleClick(nextTaskID: number) {
    if (nextTaskID !== -1) {
      this.currentSubTask = nextTaskID;
    } else {
      this.router.navigate(['/Home']);
    }
  }

  ngOnDestroy(): void {
    this.subTaskSubscription.unsubscribe();
    this.taskObjectSubscription.unsubscribe();
  }
}
