import {Component, Input} from '@angular/core';
import {Task} from '../model/task';

/***
 * This component will be used to display a task, showing all its values in a .card
 */
@Component({
  selector: 'app-task-displayer',
  templateUrl: './task-displayer.component.html',
  styleUrls: ['./task-displayer.component.css']
})
/***
 * Component used to display a task, given as input
 */
export class TaskDisplayerComponent {

  // The task to describe
  @Input()
  task: Task;
}
