import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TaskObject} from '../model/taskObject';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/***
 * This service is used to gather information obout task object from server.
 */
export class TaskObjectService {

  // Contains information about the gathered task object
  taskObject: TaskObject;
  // Contains information about the gathered list of task object
  taskObjects: TaskObject[];
  // Contains information about the list of all task object
  allTaskObjects: TaskObject[];
  // Stores the list of available types
  types: string[];
  // This is the subject to listen to get updated task object.
  taskObjectSubject = new Subject<TaskObject>();
  // This is the subject to listen to get updated list of task objects.
  taskObjectsSubject = new Subject<TaskObject[]>();
  // This is the subject to listen to get updated list of all task objects.
  allTaskObjectsSubject = new Subject<TaskObject[]>();
  // This is the subject to listen to get updated list of all available types.
  typesSubject = new Subject<string[]>();
  // root url for request
  rootURL = '/api';

  /***
   * constructor
   * @param httpClient Service that interacts with server
   */
  constructor(private httpClient: HttpClient) { }

  /***
   * Broadcast change on the task object
   */
  emitTaskSubject() {
    this.taskObjectSubject.next(this.taskObject);
  }

  /***
   * Broadcast change on the list of task objects
   */
  emitTasksSubject() {
    this.taskObjectsSubject.next(this.taskObjects);
  }

  /***
   * Broadcast change on the list of task objects
   */
  emitAllTasksSubject() {
    this.allTaskObjectsSubject.next(this.allTaskObjects);
  }

  /***
   * Broadcast change on the list of types
   */
  emitTypesSubject() {
    this.typesSubject.next(this.types);
  }

  /***
   * Get the task object with given ID
   * @param taskObjectId Id of the task object to get from server
   * @param locationId Id of the location
   */
  getATaskObject(taskObjectId: number, locationId: number) {
    this.httpClient.get(
      this.rootURL  + '/GetObject/' + taskObjectId + '/' + locationId,
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }})
      .subscribe(
        (response: { status: number, taskObject: TaskObject, message: string}) => {
          this.taskObject = response.taskObject;
          if (this.taskObject) {
            this.sanitizeTaskObject(this.taskObject);
          }
          this.emitTaskSubject();
        },
        (error) => {
          console.log('Erreur ! : ', error);
        });
  }

  /***
   * Get the list of task objects of task object with given ID
   * @param taskObjectId Id of the task object to get from server
   */
  getTaskObjectsOfObject(taskObjectId: number) {
    this.httpClient.get(
      this.rootURL  + '/GetObjectsFromObject/' + taskObjectId,
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }})
      .subscribe(
        (response: { status: number, taskObjects: TaskObject[], message: string}) => {
          this.taskObjects = response.taskObjects;
          this.taskObjects.forEach((taskObject: TaskObject) => {
            this.sanitizeTaskObject(taskObject);
          });
          this.emitTasksSubject();
        },
        (error) => {
          console.log('Erreur ! : ', error);
        });

  }

  /***
   * Get the list of task objects of task with given ID
   * @param taskId Id of the task object to get from server
   */
  getTaskObjectsOfTask(taskId: number) {
    this.httpClient.get(
      this.rootURL  + '/GetObjectsFromTask/' + taskId,
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }})
      .subscribe(
        (response: { status: number, taskObjects: TaskObject[], message: string}) => {
          this.taskObjects = response.taskObjects;
          this.taskObjects.forEach((taskObject: TaskObject) => {
            this.sanitizeTaskObject(taskObject);
          });

          this.emitTasksSubject();
        },
        (error) => {
          console.log('Erreur ! : ', error);
        });
  }

  /***
   * Get the full list of task objects
   */
  getAllTaskObjects() {

    this.httpClient.get(
      this.rootURL  + '/GetAllObjects',
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }})
      .subscribe(
        (response: { status: number, taskObjects: TaskObject[], message: string}) => {
          this.allTaskObjects = response.taskObjects;
          this.allTaskObjects.forEach((taskObject: TaskObject) => {
            this.sanitizeTaskObject(taskObject);
          });
          this.emitAllTasksSubject();
        },
        (error) => {
          console.log('Erreur ! : ', error);
        });
  }

  /***
   * This will ensure task object is well formed.
   * @param taskObject The task object to sanitize
   */
  sanitizeTaskObject(taskObject: TaskObject) {
    if (!taskObject.grab) {
      taskObject.grab = false;
    }
    else {
      taskObject.grab = true;
    }
    taskObject.value = taskObject.value === null ? undefined : taskObject.value;
  }

  /***
   * This will associate current list of object to object
   * @param taskObjects the list of id to associates. objectId2 is the object which will be associate to objectId1
   */
  associateObjectsToObject(taskObjects: {id1: number, id2: number}[]):
    Promise<{status: number, message: string, errorMessage: string}> {
    return this.associateObjectsRoute(taskObjects, '/AssociateObjectsObject');
    // return new Promise<{status: number, message: string, errorMessage: string}>((resolve, reject) => {
    //   this.httpClient.post(
    //     this.rootURL  + '/AssociateObjectsObject',
    //     {
    //       data: {
    //         taskObjects
    //       },
    //       headers: {
    //         'Content-type' : 'application/json',
    //         Accept: 'application/json'
    //       }}).toPromise().then((response: { status: number, message: string, errorMessage: string}) => {
    //       console.log('nice!', response.message);
    //       resolve(response);
    //     },
    //     (error: { status: number, errorMessage: string}) => {
    //       console.log('Erreur ! : ', error.errorMessage);
    //       reject(error);
    //     }
    //   );
    // });
  }

  /***
   * This will associate current list of objects object to task
   * @param taskObjects The list of id to associates. objectId is the object which will be associate to taskId
   */
  associateObjectsToTask(taskObjects: {id1: number, id2: number}[]):
    Promise<{status: number, message: string, errorMessage: string}> {
    return this.associateObjectsRoute(taskObjects, '/AssociateObjectsTask');
    // return new Promise<{status: number, message: string, errorMessage: string}>((resolve, reject) => {
    //   this.httpClient.post(
    //     this.rootURL  + '/AssociateObjectsTask',
    //     {
    //       data: {
    //         taskObjects
    //       },
    //       headers: {
    //         'Content-type' : 'application/json',
    //         Accept: 'application/json'
    //       }}).toPromise().then((response: { status: number, message: string, errorMessage: string}) => {
    //       console.log('nice!', response.message);
    //       resolve(response);
    //     },
    //     (error: { status: number, errorMessage: string}) => {
    //       console.log('Erreur ! : ', error.errorMessage);
    //       reject(error);
    //     }
    //   );
    // });
  }

  /***
   * This will associate current list of objects object to task or another object
   * @param taskObjects The list of id to associates. objectId is the object which will be associate to taskId
   * @param route The route to use to associate these objects to object or task
   */
  associateObjectsRoute(taskObjects: {id1: number, id2: number }[], route: string):
    Promise<{status: number, message: string, errorMessage: string}> {

    return new Promise<{status: number, message: string, errorMessage: string}>((resolve, reject) => {
      this.httpClient.post(
        this.rootURL  + route,
        {
          data: {
            taskObjects
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }}).toPromise().then((response: { status: number, message: string, errorMessage: string}) => {
          console.log('nice!', response.message);
          resolve(response);
        },
        (error: { status: number, errorMessage: string}) => {
          console.log('Erreur ! : ', error.errorMessage);
          reject(error);
        }
      );
    });
  }

  /***
   * Get the list of object types
   */
  getAvailableTypes(){

    this.httpClient.get(
      this.rootURL  + '/GetObjectTypes',
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }})
      .subscribe((response: { status: number, types: string[]}) => {
        this.types = response.types;
        this.emitTypesSubject();
      },
      (error) => {
        console.log('Erreur ! : ', error);
      });
  }

  /***
   *  This will create a new object with the given parameters.
   * @param unityId Id of new object
   * @param name Name of new object
   * @param type Type of new object
   * @param value Value of new object
   * @param grab of Grab new object
   */
  createObject(unityId: string, name: string, type: string, value: string, grab: number):
    Promise<{status: number, message: string, errorMessage: string}>{
    return new Promise<{status: number; message: string; errorMessage: string}>((resolve, reject) => {
      this.httpClient.post(
        this.rootURL  + '/CreateObject',
        {
          data: {
            unityId,
            name,
            type,
            value,
            grab
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }}).toPromise().then((response: { status: number, message: string, errorMessage: string}) => {
          console.log('nice!', response.message);
          resolve(response);
        },
        (error: { status: number, errorMessage: string}) => {
          console.log('Erreur ! : ', error.errorMessage);
          reject(error);
        }
      );
    });
  }

  /***
   *  This will update an object with the given parameters.
   * @param id Id of new object
   * @param unityId Id of new object
   * @param name Name of new object
   * @param type Type of new object
   * @param value Value of new object
   * @param grab of Grab new object
   */
  updateObject(id: number, unityId: string, name: string, type: string, value: string, grab: number):
    Promise<{status: number, message: string, errorMessage: string}>{
    return new Promise<{status: number; message: string; errorMessage: string}>((resolve, reject) => {
      this.httpClient.post(
        this.rootURL  + '/SetObject',
        {
          data: {
            id,
            unityId,
            name,
            type,
            value,
            grab
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }}).toPromise().then((response: { status: number, message: string, errorMessage: string}) => {
            resolve(response);
        },
        (error: { status: number, errorMessage: string}) => {
          reject(error);
        }
      );
    });
  }
}
