import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TaskObject} from '../model/taskObject';
import {Subscription} from 'rxjs';
import {TaskObjectService} from '../service/task-object.service';
import {ActivatedRoute, Params} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LocationService} from '../service/location.service';
import {StringService} from '../service/string.service';

@Component({
  selector: 'app-task-object-editor',
  templateUrl: './task-object-editor.component.html',
  styleUrls: ['./task-object-editor.component.css']
})
/***
 * Component used to edit or create a new task object
 */
export class TaskObjectEditorComponent implements OnInit, OnDestroy {
// The task object to describe
  taskObject: TaskObject;
  // The task object to describe
  @Input()
  providerTaskObject: TaskObject;
  // the id of taskObject
  taskObjectId: number;
  // The task object to describe
  taskObjectSubscription = new Subscription();
  // Form builder used to generate the reactive form
  taskObjectBuilderForm: FormGroup;
  // Error message displayed when there is one, in a red box
  errorMessage: string;
  // Success message displayed when query return a message. In a blue box
  message: string;
  // True when form is initialized
  initialized: boolean;
  // List of types
  types: string[];
  // Subscription to list of types
  typesSubscription  = new Subscription();
  locationSubscription  = new Subscription();

  /***
   * Constructor
   * @param formBuilder : form builder used to describe the taskObject
   * @param taskObjectService Service used ot interact with task object from server.
   * @param locationService Service used ot interact with location from server.
   * @param route Service used to get route parameters.
   */
  constructor(private formBuilder: FormBuilder,
              private taskObjectService: TaskObjectService,
              private locationService: LocationService,
              private route: ActivatedRoute) {
    this.taskObjectSubscription = this.taskObjectService.taskObjectSubject.subscribe((taskObject: TaskObject) => {
      this.taskObject = taskObject;

      if (taskObject !== undefined) {
        this.taskObjectBuilderForm.setValue({
          unityId: taskObject.unityId,
          name: taskObject.name,
          value: taskObject.value ? taskObject.value : '',
          type: taskObject.type,
          grab: taskObject.grab ? true : false,
        });

        this.errorMessage = undefined;
      } else {
        this.errorMessage = 'An error occurred';
      }
    });

    this.typesSubscription = this.taskObjectService.typesSubject.subscribe((types: string[]) => {
      this.types = types;
    });

    this.locationSubscription = this.locationService.selectedLocationSubject
      .subscribe((location) => {
        if(this.taskObjectId !== undefined&& location !== undefined)
        {
          this.taskObjectService.getATaskObject(this.taskObjectId, location.id);
        }
    });
  }

  /***
   * Called one time only, once the page is initialized
   */
  ngOnInit(): void {

    this.initForm();

    this.taskObjectService.getAvailableTypes();

    // If there is no provided task object, then use the route params if it is properly set.
    if (this.providerTaskObject === undefined) {
      this.route.params.subscribe( (params: Params) => {
        this.taskObjectId = params.taskObjectId;
        if (this.taskObjectId !== undefined&& this.locationService.selectedLocation !== undefined)
        {
          this.taskObjectService.getATaskObject(this.taskObjectId, this.locationService.selectedLocation);
        }
      });
    }
    // Else use the provided one.
    else {
      this.taskObject = this.providerTaskObject;
    }
  }

  // convenience getter for easy access to form fields
  get controls() { return this.taskObjectBuilderForm.controls; }

  /**
   * Method that will initialize the form.
   */
  initForm() {
    this.taskObjectBuilderForm = this.formBuilder.group(
      {
        unityId: ['', Validators.required],
        name: ['', Validators.required],
        type: ['', Validators.required],
        grab: [false, Validators.required],
        value: ['', !Validators.required]
      });

    this.initialized = true;
  }

  /***
   * Create or update a task object
   */
  createTaskObject() {
    this.message = undefined;
    this.errorMessage = undefined;

    if (this.taskObjectBuilderForm.invalid) {
      this.errorMessage = 'fields are not properly filled';
      return;
    }

    const id = this.taskObjectId;
    const name = StringService.removeQuote(this.controls.name.value);
    const unityId = StringService.removeQuote(this.controls.unityId.value);
    const value = StringService.removeQuote(this.controls.value.value);
    const grab = this.controls.grab.value;
    const type = this.controls.type.value;

    if (this.taskObjectId === undefined) {

      // Create a new one
      this.taskObjectService.createObject(unityId, name, type, value, grab)
        .then((answer: {status: number; message: string; errorMessage: string}) => {
          // console.log(answer.message);
          this.message = answer.message;
          this.errorMessage = answer.errorMessage;

          this.updateListOfTaskObject();
      });
    }
    else {
      // update an existing one
      console.log('update');

      this.taskObjectService.updateObject(id, unityId, name, type, value, grab)
        .then((answer: {status: number; message: string; errorMessage: string}) => {
          // console.log(answer.message);
          this.message = answer.message;
          this.errorMessage = answer.errorMessage;

          this.updateListOfTaskObject();
      });
    }
  }

  /***
   * used to update the list of task object everywhere
   */
  updateListOfTaskObject() {
    this.taskObjectService.getAllTaskObjects();
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
    this.typesSubscription.unsubscribe();
    this.taskObjectSubscription.unsubscribe();
  }
}
