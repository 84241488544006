import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
/***
 * This service is used to provide methods to sanitize strings.
 */
export class StringService {

  /***
   * This will remove quote from given string
   * @param label The string whom quotes has to be removed
   * @return the sanitized string
   */
  static removeQuote(label: string): string {
    if(label === undefined || label === null) {
      return label;
    }
    else {
      while (label.indexOf('\"') !== -1) {
        label = label.replace('\"', '\'');
      }
      return label;
    }
  }
}
