import { Injectable } from '@angular/core';
import {Action} from '../model/action';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
/***
 * Service used to interact with server actions.
 */
export class ActionService {
  // An action
  action: Action;
  // The subject to listen to get the action
  actionSubject = new Subject<{ status: number, message: string, action: Action }>();
  // The list of actions
  actions: Action[];
  // The subject to listen to get the list of actions
  actionsSubject = new Subject<{ status: number, message: string, actions: Action[] }>();
  // root url
  rootURL = '/api';

  /***
   * Constructor
   * @param httpClient The service used to make http request
   */
  constructor(private httpClient: HttpClient) { }

  /***
   * This will broadcast the actions changes to all component listening to it
   */
  emitActions(response: { status: number, message: string, actions: Action[] }) {
    this.actionsSubject.next(response);
  }

  /***
   * This will broadcast the action change to all component listening to it
   */
  emitAction(response: { status: number, message: string, action: Action }) {
    this.actionSubject.next(response);
  }

  /***
   * This will gather all the actions
   */
  getAllActions() {
    this.httpClient.get(
      this.rootURL + '/GetAllActions',
      {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }).subscribe((response: { status: number, message: string, actions: Action[] }) => {
        // Success
        this.actions = response.actions;
        this.emitActions(response);
      },
      (err) => {
        // Error
        console.log('Erreur ! : ', err);
      }
    );
  }

  /***
   * This will gather all the actions
   */
  getAction(actionId: number) {
    this.httpClient.get(
      this.rootURL + '/GetAllActions',
      {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }).subscribe((response: { status: number, message: string, actions: Action[] }) => {
        // Success
        this.action = response.actions[actionId];
        const responseBis = {
          status: response.status,
          message: response.message,
          action: response.actions[actionId]
        };
        this.emitAction(responseBis);
      },
      (err) => {
        // Error
        console.log('Erreur ! : ', err);
      }
    );
  }

  /***
   * This will create a new action
   */
  createAction(name: string): Promise<{status: number, message: string}>{
    return new Promise<{status: number, message: string}> ((resolve, reject) => {
      this.httpClient.post(
        this.rootURL + '/CreateAction',
        {
          data: {
            name
          },
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        })
        .toPromise().then((response: { status: number, message: string}) => {
          // Success
          resolve(response);
        },
        err => {
          // Error
          reject({errorMessage: err});
        }
      );
    });
  }

  /***
   * This update given action
   */
  updateAction(id: number, name: string): Promise<{status: number, message: string}>{
    return new Promise<{status: number, message: string}> ((resolve, reject) => {
      this.httpClient.post(
        this.rootURL + '/SetAction',
        {
          data: {
            id,
            name
          },
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        })
        .toPromise().then((response: { status: number, message: string}) => {
          // Success
          resolve(response);
        },
        err => {
          // Error
          reject({errorMessage: err});
        }
      );
    });
  }
}
