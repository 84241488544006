import { Component } from '@angular/core';

@Component({
  selector: 'app-login-failure',
  templateUrl: './login-failure.component.html',
  styleUrls: ['./login-failure.component.css']
})
/***
 * This component describe the page displayed when the credentials are wrong.
 */
export class LoginFailureComponent  {}
