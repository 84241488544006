import {Component, Input} from '@angular/core';
import {TaskLink} from "../model/task-link";

@Component({
  selector: 'app-sub-task-displayer',
  templateUrl: './sub-task-displayer.component.html',
  styleUrls: ['./sub-task-displayer.component.css']
})
/***
 * Component used to display properties of the provided subtask
 */
export class SubTaskDisplayerComponent {
  // current subTask
  @Input()
  subTask: TaskLink;
}
