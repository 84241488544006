import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SubTaskService} from '../service/sub-task.service';
import {TaskService} from '../service/task-service';
import {Task} from '../model/task';
import {SubTask} from '../model/sub-task';
import {Subscription} from 'rxjs';
import {TaskLink} from '../model/task-link';
import {StringService} from '../service/string.service';
@Component({
  selector: 'app-task-link-builder',
  templateUrl: './task-link-builder.component.html',
  styleUrls: ['./task-link-builder.component.css']
})
/***
 * This component is used to create or edit links between subtasks of a task
 */
export class TaskLinkBuilderComponent implements OnInit, OnChanges, OnDestroy {
  // Form builder used to generate the reactive form
  linkBuilderForm: FormGroup;
  // Error message displayed when there is one, in a red box
  errorMessage: string;
  // Success message displayed when query return a message. In a blue box
  message: string;
  // Boolean used to know if the reactive form has been initialized or not.
  initialized: boolean;
  // linkId: number;
  @Input()
  linkId: number;
  // linkId: number;
  @Input()
  taskId: string;
  // List of tasks, use to select the current one
  tasks: Task[];
  // Subscription to the list of tasks
  tasksSubscription = new Subscription();
  // List of subtasks, use to select the current one
  subTasks: SubTask[];
  // Subscription to the list of subtasks
  subTasksSubscription = new Subscription();

  /***
   * Constructor
   * @param formBuilder : form builder used to describe the task
   * @param taskService: service used to interact with task in DB
   * @param subTaskService: service used to interact with subtask in DB
   */
  constructor(private formBuilder: FormBuilder,
              private taskService: TaskService,
              private subTaskService: SubTaskService) {

    // initialize the form
    this.initForm();

    // Get the full list of available tasks
    this.tasksSubscription = this.taskService.tasksSubject.subscribe((tasks: Task[]) => {
      this.tasks = tasks;
    });

    // Get the full list of available subtasks
    this.subTasksSubscription = this.subTaskService.subTasksSubject.subscribe((subTasks: SubTask[]) => {
      this.subTasks = subTasks;
    });
  }

  ngOnInit() {
    // get all task and subtasks
    this.taskService.getTasks();
    this.subTaskService.getAllSubTasks();

  }

  /**
   * Method that will initialize the form.
   */
  initForm() {
    this.linkBuilderForm = this.formBuilder.group(
      {
        condition1: [
          {value: ''},
          Validators.required],
        condition2: [
          {value: ''},
          Validators.required],
        condition3: [
          {value: ''},
          Validators.required],
        condition4: [
          {value: ''},
          Validators.required],
        condition5: [
          {value: ''},
          Validators.required],
        isConditional:  [
          {value: false},
          Validators.required],
        selectedCurrentTask:  [
          {value: false},
          Validators.required],
        selectedCurrentSubTask:  [
          {value: false},
          Validators.required],
        selectedNextSubTask1:  [
          {value: false},
          Validators.required],
        selectedNextSubTask2:  [
          {value: false},
          Validators.required],
        selectedNextSubTask3:  [
          {value: false},
          Validators.required],
        selectedNextSubTask4:  [
          {value: false},
          Validators.required],
        selectedNextSubTask5:  [
          {value: false},
          Validators.required]
      });

    this.initialized = true;

    this.initializeWithNoCondition();
  }

  // convenience getter for easy access to form fields
  get controls() { return this.linkBuilderForm.controls; }

  /**
   * Method called when you create a subtask. It will send subtask properties to the server through the subTaskService.
   */
  createTaskLink() {
    const isConditional = this.linkBuilderForm.get('isConditional').value;
    const currentTask = this.linkBuilderForm.get('selectedCurrentTask').value;
    const currentSubTask = this.linkBuilderForm.get('selectedCurrentSubTask').value;
    const conditions = [];
    const nextTasks = [];
    let valid = true;
    for (let i = 1; i < 6; i++){
      conditions[i] = StringService.removeQuote(this.linkBuilderForm.get('condition' + i).value) || null;
      nextTasks[i] = this.linkBuilderForm.get('selectedNextSubTask' + i).value || -1;
      valid = valid && ((conditions[i] !== null && nextTasks[i] !== '-1') || nextTasks[i] === -1 || nextTasks[i] === '-1' );
    }

    // Check if form is valid.
    if ((isConditional && !valid) || currentTask === undefined || currentTask.value === false
      || currentSubTask === undefined || currentSubTask.value === false) {
      this.errorMessage = 'Merci de remplir les conditions manquantes';
      this.message = undefined;
      return;
    }

    // create the task if in create mode
    if (this.linkId === undefined) {
      this.subTaskService.createTaskLink(
        currentTask,
        currentSubTask,
        nextTasks[1],
        nextTasks[2],
        nextTasks[3],
        nextTasks[4],
        nextTasks[5],
        conditions[1],
        conditions[2],
        conditions[3],
        conditions[4],
        conditions[5],
      ).then((answer: {errorMessage: string, message: string}) => {
        this.errorMessage = answer.errorMessage;
        this.message = answer.message;
        this.subTaskService.GetTaskLinksOfTask(currentTask);
        this.updateListOfTask();
      });
    } else {
      this.subTaskService.updateTaskLink(
        this.linkId,
        currentTask,
        currentSubTask,
        nextTasks[1],
        nextTasks[2],
        nextTasks[3],
        nextTasks[4],
        nextTasks[5],
        conditions[1],
        conditions[2],
        conditions[3],
        conditions[4],
        conditions[5]).then(
        (answer: {errorMessage: string, message: string}) => {
          this.errorMessage = answer.errorMessage;
          this.message = answer.message;
          this.subTaskService.GetTaskLinksOfTask(currentTask);
          this.updateListOfTask();

        });
    }
  }

  /***
   * Method used to fill the form when you select another link to edit
   */
  onLinkChange() {
    this.message = undefined;
    this.errorMessage = undefined;
    if (this.linkId !== undefined) {
      this.subTaskService.getTaskLink(this.linkId)
        .then((response: { status: number, message: string, tasksLink: TaskLink, errorMessage: string }) => {
        if (response.status === 200) {
          this.controls.selectedCurrentTask.patchValue(response.tasksLink.currentTask);
          this.controls.selectedCurrentSubTask.patchValue(response.tasksLink.currentSubtask);
          this.controls.selectedNextSubTask1.patchValue(response.tasksLink.nextTask1);
          this.controls.selectedNextSubTask2.patchValue(response.tasksLink.nextTask2);
          this.controls.selectedNextSubTask3.patchValue(response.tasksLink.nextTask3);
          this.controls.selectedNextSubTask4.patchValue(response.tasksLink.nextTask4);
          this.controls.selectedNextSubTask5.patchValue(response.tasksLink.nextTask5);
          this.controls.isConditional.patchValue(response.tasksLink.condition1 !== null);
          this.controls.condition1.patchValue(response.tasksLink.condition1);
          this.controls.condition2.patchValue(response.tasksLink.condition2);
          this.controls.condition3.patchValue(response.tasksLink.condition3);
          this.controls.condition4.patchValue(response.tasksLink.condition4);
          this.controls.condition5.patchValue(response.tasksLink.condition5);
        } else {
          this.errorMessage = response.errorMessage;
          this.message = response.message;
        }
      });
    } else {
      if (this.initialized) {
        this.initializeWithNoCondition();
      }
    }
  }

  /***
   * listener to change of the selected link
   */
  ngOnChanges() {
    this.onLinkChange();
  }

  /***
   * Method that initialize the form without any condition
   */
  initializeWithNoCondition() {
    this.controls.isConditional.patchValue(false);
    this.controls.selectedCurrentTask.patchValue('');
    this.controls.selectedCurrentSubTask.patchValue('');
    this.controls.condition1.patchValue('');
    this.controls.condition2.patchValue('');
    this.controls.condition3.patchValue('');
    this.controls.condition4.patchValue('');
    this.controls.condition5.patchValue('');
    this.controls.selectedNextSubTask1.patchValue('-1');
    this.controls.selectedNextSubTask2.patchValue('-1');
    this.controls.selectedNextSubTask3.patchValue('-1');
    this.controls.selectedNextSubTask4.patchValue('-1');
    this.controls.selectedNextSubTask5.patchValue('-1');
  }

  /***
   * Update the list of subtask everywhere
   */
  updateListOfTask() {
    this.subTaskService.getAllSubTasks();
  }

  /***
   * This will delete current task link, then navigate to /
   */
  deleteTaskLink()
  {

    this.subTaskService.deleteTaskLink(this.linkId).then((answer: { errorMessage: string, message: string }) => {
      if (answer.errorMessage) {
        this.errorMessage = answer.errorMessage;
      } else {
        this.message = answer.message;
        this.subTaskService.GetTaskLinksOfTask(parseInt(this.taskId, 10));
      }
    });
  }

  ngOnDestroy(): void {
    this.subTasksSubscription.unsubscribe();
    this.tasksSubscription.unsubscribe();
  }
}
