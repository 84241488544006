import {Component, OnDestroy, OnInit} from '@angular/core';
import {QuestionnaireModel} from '../model/questionnaire-model';
import {QuestionnaireService} from '../service/questionnaire-service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import {QuestionModel} from '../model/question-model';
import {QuestionnaireDataModel} from '../model/questionnaire-data-model';
import {StringService} from '../service/string.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css']
})
/***
 * This component describe how to display a questionnaire, and let it be filled by a user.
 */
export class QuestionnaireComponent implements OnInit, OnDestroy {
  // id of current questionnaire
  questionnaireId: number;
  // questionnaire to display
  questionnaire: QuestionnaireModel;
  // model of what to send to server
  questionnaireData: QuestionnaireDataModel;
  // list of questions of this questionnaire
  questions: QuestionModel[];
  // subscription to the questionnaire selected / to display
  questionnaireSubscription: Subscription;
  // info message to display
  message: string;
  // error message to display
  errorMessage: string;
  // tells if something is loading or not
  isLoading: boolean;

  /***
   * Constructor
   * @param questionnaireService Service used to interact with questionnaire server side
   * @param route service used to know which questionnaire id is used.
   */
  constructor(private questionnaireService: QuestionnaireService, private route: ActivatedRoute) {

    this.questionnaireSubscription = this.questionnaireService.questionnaireSubject
      .subscribe((questionnaire: QuestionnaireModel) => {

      this.questionnaire = questionnaire;
      if (this.questionnaire)
      {
        this.message = undefined;
        this.questions = questionnaire.questions;
      }
      else {
        this.questions = [];
        this.message = 'There is no questionnaire for this id';
      }

      this.updateQuestionnaireFields();
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe( (params: Params) => {
      this.questionnaireId = params.questionnaireId;
      if (this.questionnaireId !== undefined)
      {
        this.questionnaireService.getQuestionnaire(this.questionnaireId);
      }
    });
  }

  /**
   * This is used to make sure the fields reflect what is stored in the DB
   */
  updateQuestionnaireFields(): void {
    this.questionnaireData = {
      id: this.questionnaire ? this.questionnaire.id : -1,
      name: this.questionnaire ? this.questionnaire.name : '',
      description: this.questionnaire ? this.questionnaire.description : '',
      answers: []
    };

    for (let i = 0, maxI = this.questions.length; i < maxI; i++) {

      this.questionnaireData.answers[i] = {
        questionId: this.questions[i].questionId,
        questionLabel: StringService.removeQuote(this.questions[i].questionLabel),
        answers: []
      };
    }
  }

  /***
   * Update data to send to server when changing the value of a text, a range, or a radio field
   * @param questionIndex index of question
   * @param answer answer provided
   */
  changeValue(questionIndex: number,
              answer: string) {
    this.questionnaireData.answers[questionIndex].answers = [StringService.removeQuote(answer)];
  }

  /***
   * Update data to send to server when changing the value of check box
   * @param questionIndex index of question
   * @param answer answer provided
   * @param checked boolean that says if the value has to be added or removed from list of answers.
   */
  changeCheckboxValue(questionIndex: number,
                      answer: string,
                      checked: boolean) {
    if (checked) {
      this.questionnaireData.answers[questionIndex].answers.push(StringService.removeQuote(answer));
    }
    else {
      this.questionnaireData.answers[questionIndex].answers
        .splice(this.questionnaireData.answers[questionIndex].answers.indexOf(StringService.removeQuote(answer)), 1);
    }
  }

  /***
   * This method is used to send the data to server in order to store it
   */
  sendQuestionnaire() {
    this.errorMessage = undefined;
    this.message = undefined;
    this.isLoading = true;
    this.questionnaireService.sendQuestionnaireData(this.questionnaireData)
      .then((answer: {status: number, message: string, errorMessage: string}) => {
      if (answer.errorMessage) {
        this.errorMessage = answer.errorMessage;
      }
      else if (answer.message) {
        this.message = answer.message;
      }
      else if (answer.status === 500) {
        this.errorMessage = 'Internal error';
      }
      this.isLoading = false;
    });
  }

  /***
   * Method used to get the value to display for a specific range
   * @param id id of the related question
   */
  getRangeAnswerForId(id: number) {
    for (let i = 0, maxI =  this.questionnaireData.answers.length; i < maxI; i++) {
      if (this.questionnaireData.answers[i].questionId === id) {
        return this.questionnaireData.answers[i].answers;
      }
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.questionnaireSubscription.unsubscribe();
  }

  /***
   * This method will return true if one of the next answer is selected
   * @param questionIndex Index of the question
   * @param i index of the answer
   */
  isStarHighlighted(questionIndex: number, i: number) {
    const listOfAnswers = this.questions[questionIndex].answers;
    const storedAnswer = this.questionnaireData.answers[questionIndex].answers[0];
    const currentAnswer = listOfAnswers[i];

    for (let j = 0, maxJ = listOfAnswers.length; j < maxJ; j++) {

      if (storedAnswer !== undefined && ((currentAnswer === storedAnswer) || (i < listOfAnswers.indexOf(storedAnswer)))) {
        return true;
      }
    }
    return false;
  }
}
