import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TaskObject} from '../model/taskObject';
import {ActivatedRoute, Params} from '@angular/router';
import {TaskObjectService} from '../service/task-object.service';
import {Subscription} from 'rxjs';
import {LocationService} from '../service/location.service';

@Component({
  selector: 'app-task-object-displayer',
  templateUrl: './task-object-displayer.component.html',
  styleUrls: ['./task-object-displayer.component.css']
})
/***
 * This is used to describe the task object. It can be provided as object (input) or as ID (url).
 */
export class TaskObjectDisplayerComponent implements OnInit, OnDestroy {

  // The task object to describe
  taskObject: TaskObject;
  // The task object to describe
  @Input()
  providerTaskObject: TaskObject;
  // the id of taskObject
  taskObjectId: number;
  // The task object to describe
  taskObjectSubscription = new Subscription();
  // Boolean used to know if anchors have to be displayed or not
  showAnchors: boolean;
  // Boolean used to know if anchors have to be displayed or not
  showSubObjects: boolean;


  /***
   * Constructor
   * @param taskObjectService Service used ot interact with task object from server.
   * @param locationService Service used ot interact with location from server.
   * @param route Service used to get route parameters.
   */
  constructor(private taskObjectService: TaskObjectService, private locationService: LocationService, private route: ActivatedRoute) {
  }

  /***
   * Called one time only, once the page is initialized
   */
  ngOnInit(): void {
    // If there is no provided task object, then use the route params if it is properly set.
    if (this.providerTaskObject === undefined) {
      this.route.params.subscribe( (params: Params) => {
        this.taskObjectId = params.taskObjectId;

        this.taskObjectSubscription = this.taskObjectService.taskObjectSubject.subscribe((taskObject: TaskObject) => {
          this.taskObject = taskObject;
        });

        if (this.taskObjectId !== undefined)
        {
          this.taskObjectService.getATaskObject(this.taskObjectId, this.locationService.selectedLocation);
        }
      });
    }
    // Else use the provided one.
    else {
      this.taskObject = this.providerTaskObject;
    }
  }

  ngOnDestroy(): void {
    this.taskObjectSubscription.unsubscribe();
  }
}
