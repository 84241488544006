import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from "../../service/auth-service";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
/***
 * This component describe the login page.
 */
export class SignInComponent implements OnInit {

  // Form used to contain the credentials fields.
  identificationForm: FormGroup;

  /**
   * Constructor
   * @param formBuilder Angular service used to handle form
   * @param authService Service used to manage authentication
   */
  constructor(private formBuilder: FormBuilder,
              private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Method that will initialize the form.
   */
  initForm() {
    // This make login and password required. You can't submit if it's empty
    this.identificationForm = this.formBuilder.group(
      {
        login: ['', Validators.required],
        password: ['', Validators.required],
      }
    );
  }

  /**
   * Method called when you click connect. It will send credentials to the server through the AuthService.
   */
  onLogin() {
    const login = this.identificationForm.get('login').value;
    const password = this.identificationForm.get('password').value;
    // use service!
    this.authService.login(login, password);
  }
}
