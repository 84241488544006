import { Pipe, PipeTransform } from '@angular/core';
import {TaskObject} from '../model/taskObject';

@Pipe({
  name: 'FilterContainsSubObject',
  pure: false
})
/***
 * This is used to filter a list of json objects.
 */
export class FilterContainsSubObject  implements PipeTransform {
  transform(items: Object[], textFilter): Object[] {
    if (!items ) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter((jsonObject: Object) => {
      // return jsonObject.subObjects !== undefined && jsonObject.subObjects.length > 1;
      if(textFilter === undefined || textFilter === '')
      {
        return true;
      }
      for(let key of Object.keys(jsonObject)) {
        if((jsonObject[key]+'').toUpperCase().indexOf((textFilter+'').toUpperCase()) !== -1)
        {
          return true;
        }
      }
      return false;
    });
  }
}
