import {Component, OnDestroy} from '@angular/core';
import {Action} from '../model/action';
import {ActionService} from '../service/action.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-action-displayer',
  templateUrl: './action-displayer.component.html',
  styleUrls: ['./action-displayer.component.css']
})
/***
 * Class used to describe the action displayer component, used to display actions.
 */
export class ActionDisplayerComponent implements OnDestroy{
  // current action
  action: Action;
  // subscription to action
  actionSubscription = new Subscription();
  // current action id
  actionId: number;

  /***
   *  Constructor
   * @param actionService Service used to interact with DB action
   * @param route Service used to get route params.
   */
  constructor(private actionService: ActionService, private route: ActivatedRoute) {
    this.actionSubscription = this.actionService.actionSubject
      .subscribe((response: { status: number, message: string, action: Action }) => {
      this.action = response.action;
    });

    this.route.params.subscribe((params: Params) => {
      if (params.actionId !== undefined) {
        this.actionId = params.actionId;

        this.actionService.getAction(this.actionId - 1);
      }
    });

  }

  ngOnDestroy(): void {
    this.actionSubscription.unsubscribe();
  }
}
