import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Task} from '../model/task';
import {Subject} from 'rxjs';
import {LocationService} from './location.service';

@Injectable({
  providedIn: 'root'
})
/***
 * Service used to interact with server tasks data
 */
export class TaskService {
  // list of task
  tasks: Task[];
  // Subject to listen to get the list of tasks
  tasksSubject = new Subject<Task[]>();
  // root url
  rootURL = '/api';

  /***
   * Constructor
   * @param locationService Service used to know current location
   * @param httpClient The client to server
   */
  constructor(private locationService: LocationService, private httpClient: HttpClient) { }

  /***
   * This will update the web-app tasks
   */
  emitTaskSubject() {
    this.tasksSubject.next(this.tasks.slice());
  }

  /***
   * This will gather the tasks and update the list in the web-app
   */
  getTasks(): void {

    this.httpClient.get(
      this.rootURL  + '/GetTasks/',
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }}).subscribe(
      (response: { status: number, tasks: Task[], message: string}) => {
        this.tasks = response.tasks;

        this.emitTaskSubject();
      },
      (error) => {
        console.log('Erreur ! : ', error);
      }
    );
  }

  /***
   * This will gather a task given its ID
   * @param taskId id of the task to get
   */
  getTask(taskId: number): Promise<any> {
    return new Promise((resolve, reject) => {
    this.httpClient.get(
      this.rootURL  + '/GetTask/' + taskId,
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }
      }).toPromise()
      .then((response: { status: number, message: string, task: Task }) => {
          // Success
          resolve(response);
        },
        err => {
          // Error
          reject({errorMessage: err});
        }
      );
  });
  }

  /***
   * This will create a new task given a list of info
   * @param id id of the new task
   * @param barcode barcode of the new task
   * @param description description of the new task
   * @param type type of the new task
   * @param duration duration of the new task
   */
  createTask(id: number, barcode: number, description: string, type: string, duration: number): Promise<any> {
    return this.sendTaskToRoute(id, barcode, description, type, duration, '/createTask');
    // return new Promise((resolve, reject) => {
    //  this.httpClient.post(
    //   this.rootURL  + '/createTask',
    //   {
    //     data: {
    //       id,
    //       barcode,
    //       description,
    //       type,
    //       duration
    //     },
    //     headers: {
    //       'Content-type' : 'application/json',
    //       Accept: 'application/json'
    //     }})
    //    .toPromise()
    //    .then((response: any) => {
    //        // Success
    //        resolve({message: response.message, errorMessage: response.errorMessage});
    //      },
    //      err => {
    //        // Error
    //        reject({errorMessage: err});
    //      }
    //    );
    // });
  }

  /***
   * This will update an existing task given a list of info
   * @param id id of the task to update
   * @param barcode new barcode of the task to update
   * @param description new description of the task to update
   * @param type new type of the task to update
   * @param duration new duration of the task to update
   */
  updateTask(id: number, barcode: number, description: string, type: string, duration: number): Promise<any> {
    return this.sendTaskToRoute(id, barcode, description, type, duration, '/updateTask');
    // return new Promise((resolve, reject) => {
    //   this.httpClient.post(
    //     this.rootURL  + '/updateTask',
    //     {
    //       data: {
    //         id,
    //         barcode,
    //         description,
    //         type,
    //         duration
    //       },
    //       headers: {
    //         'Content-type' : 'application/json',
    //         Accept: 'application/json'
    //       }})
    //     .toPromise()
    //     .then((response: any) => {
    //         // Success
    //         resolve({message: response.message, errorMessage: response.errorMessage});
    //       },
    //       err => {
    //         // Error
    //         reject({errorMessage: err});
    //       }
    //     );
    // });
  }

  /***
   * This send the task info to the given route
   * @param id id of the new task
   * @param barcode barcode of the new task
   * @param description description of the new task
   * @param type type of the new task
   * @param duration duration of the new task
   * @param route Route where to send the data
   */
  sendTaskToRoute(id: number, barcode: number, description: string, type: string, duration: number, route: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(
        this.rootURL  + route,
        {
          data: {
            idLocation: this.locationService.selectedLocation,
            id,
            barcode,
            description,
            type,
            duration
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }})
        .toPromise()
        .then((response: any) => {
            // Success
            resolve({message: response.message, errorMessage: response.errorMessage});
          },
          err => {
            // Error
            reject({errorMessage: err});
          }
        );
    });
  }
}
