import {Component, OnDestroy} from '@angular/core';
import {Location} from '../model/location';
import {LocationService} from '../service/location.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-location-chooser',
  templateUrl: './location-chooser.component.html',
  styleUrls: ['./location-chooser.component.css']
})
/***
 * This component describe a location chooser, which consist in a combo box filed with all available locations
 */
export class LocationChooserComponent implements OnDestroy{

  // list of available locations
  locations: Location[];
  // subscription to the available routes
  locationSubscription = new Subscription();
  // Id of the selected location
  selectedLocation: string;

  /***
   * Const
   * @param locationService Service used to interact with location
   */
  constructor(private locationService: LocationService) {
    this.locationSubscription = locationService.
    locationsSubject.subscribe((answer: { status: number, message: string, locations: Location[] }) => {
      this.locations = answer.locations;

      this.locations.forEach((location: Location) => {
        if (location.selected) {
          this.selectedLocation = location.id.toString();
          this.locationService.selectedLocation = location.id;
          this.locationService.emitSelectedLocation(location);
        }
      });
    });

    this.locationService.getAllLocations();
  }

  /***
   * Called when the location is selected.
   */
  selectLocation(): void{
    // console.log(this.selectedLocation);
    this.locationService.selectLocation(parseInt(this.selectedLocation));
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }
}
