import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { TaskDisplayerComponent } from './task-displayer/task-displayer.component';
import {HttpClientModule} from '@angular/common/http';
import { TrackerSheetComponent } from './tracker-sheet/tracker-sheet.component';
import { SubTaskDisplayerComponent } from './sub-task-displayer/sub-task-displayer.component';
import { TrainingComponent } from './training/training.component';
import {RouterModule} from '@angular/router';
import { TaskBuilderComponent } from './task-builder/task-builder.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SubTaskBuilderComponent } from './sub-task-builder/sub-task-builder.component';
import { TaskLinkBuilderComponent } from './task-link-builder/task-link-builder.component';
import { ToggleMenuComponent } from './toggle-menu/toggle-menu.component';
import { TaskLinkEditorComponent } from './task-link-editor/task-link-editor.component';
import { AllSubTaskDisplayerComponent } from './all-sub-task-displayer/all-sub-task-displayer.component';
import { TaskObjectDisplayerComponent } from './task-object-displayer/task-object-displayer.component';
import { TaskObjectsOfTaskDisplayerComponent } from './task-objects-of-task-displayer/task-objects-of-task-displayer.component';
import {FilterContainsSubObject} from './filter/filter-contains-sub-object';
import { AddRemoveObjectComponent } from './add-remove-object/add-remove-object.component';
import { TaskObjectEditorComponent } from './task-object-editor/task-object-editor.component';
import { ActionDisplayerComponent } from './action-displayer/action-displayer.component';
import { ActionBuilderComponent } from './action-builder/action-builder.component';
import { LocationChooserComponent } from './location-chooser/location-chooser.component';
import { LocationBuilderComponent } from './location-builder/location-builder.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { LoginFailureComponent } from './auth/login-failure/login-failure.component';
import {AuthGuardService} from './service/auth-guard-service';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { DataDisplayerComponent } from './data-displayer/data-displayer.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

// Defines available route in the web app
const appRoutes = [
  {path: 'SignIn', component: SignInComponent},
  {path: 'LoginFailure', component: LoginFailureComponent},
  {path: 'Training/:taskId', canActivate: [AuthGuardService], component: TrainingComponent},
  {path: 'TaskBuilder', canActivate: [AuthGuardService], component: TaskBuilderComponent},
  {path: 'TaskEditor/:taskId', canActivate: [AuthGuardService], component: TaskBuilderComponent},
  {path: 'SubTaskEditor/:taskId', canActivate: [AuthGuardService], component: SubTaskBuilderComponent},
  {path: 'SubTaskBuilder', canActivate: [AuthGuardService], component: SubTaskBuilderComponent},
  {path: 'LinkEditor/:linkId', canActivate: [AuthGuardService], component: TaskLinkBuilderComponent},
  {path: 'LinkEditor', canActivate: [AuthGuardService], component: TaskLinkEditorComponent},
  {path: 'LinkBuilder', canActivate: [AuthGuardService], component: TaskLinkBuilderComponent},
  {path: 'FollowTask/:taskId', canActivate: [AuthGuardService], component: TrainingComponent},
  {path: 'TaskObject/:taskObjectId', canActivate: [AuthGuardService], component: TaskObjectDisplayerComponent},
  {path: 'TaskObjectEditor', canActivate: [AuthGuardService], component: TaskObjectEditorComponent},
  {path: 'TaskObjectEditor/:taskObjectId', canActivate: [AuthGuardService], component: TaskObjectEditorComponent},
  {path: 'TaskObjectsOfTask/:taskId', canActivate: [AuthGuardService], component: TaskObjectsOfTaskDisplayerComponent},
  {path: 'TaskObjectsOfObject/:taskObjectId', canActivate: [AuthGuardService], component: TaskObjectsOfTaskDisplayerComponent},
  {path: 'AddRemoveObjectToTask/:taskId', canActivate: [AuthGuardService], component: AddRemoveObjectComponent},
  {path: 'AddRemoveObjectToObject/:taskObjectId', canActivate: [AuthGuardService], component: AddRemoveObjectComponent},
  {path: 'Action/:actionId', canActivate: [AuthGuardService], component: ActionDisplayerComponent},
  {path: 'ActionBuilder', canActivate: [AuthGuardService], component: ActionBuilderComponent},
  {path: 'ActionEditor/:actionId', canActivate: [AuthGuardService], component: ActionBuilderComponent},
  {path: 'LocationBuilder', canActivate: [AuthGuardService], component: LocationBuilderComponent},
  {path: 'LocationEditor/:locationId', canActivate: [AuthGuardService], component: LocationBuilderComponent},
  {path: 'Questionnaire/:questionnaireId', canActivate: [AuthGuardService], component: QuestionnaireComponent},
  {path: 'Data/:dataId', canActivate: [AuthGuardService], component: DataDisplayerComponent},
  {path: 'Home', canActivate: [AuthGuardService], component: TrackerSheetComponent},
  {path: '', redirectTo: 'Home', pathMatch: 'full'},
  {path: '**', redirectTo: 'Home'}
];

@NgModule({
  declarations: [
    AppComponent,
    TaskDisplayerComponent,
    TrackerSheetComponent,
    SubTaskDisplayerComponent,
    TrainingComponent,
    TaskBuilderComponent,
    SubTaskBuilderComponent,
    TaskLinkBuilderComponent,
    ToggleMenuComponent,
    TaskLinkEditorComponent,
    AllSubTaskDisplayerComponent,
    TaskObjectDisplayerComponent,
    TaskObjectsOfTaskDisplayerComponent,
    FilterContainsSubObject,
    AddRemoveObjectComponent,
    TaskObjectEditorComponent,
    ActionDisplayerComponent,
    ActionBuilderComponent,
    LocationChooserComponent,
    LocationBuilderComponent,
    SignInComponent,
    LoginFailureComponent,
    QuestionnaireComponent,
    DataDisplayerComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
