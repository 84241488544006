import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Location} from '../model/location';

@Injectable({
  providedIn: 'root'
})
/***
 * This service is used to interact with location on server.
 */
export class LocationService {

  // the location
  location: Location;
  // The subject to listen to get the location
  locationSubject = new Subject<{ status: number, message: string, location: Location }>();
  // The list of locations
  locations: Location[];
  // The subject to listen to get the list of actions
  locationsSubject = new Subject<{ status: number, message: string, locations: Location[] }>();
  // root url
  rootURL = '/api';
  // id of the selected location
  selectedLocation: number;
  selectedLocationSubject = new Subject<Location>();


  /***
   * Constructor
   * @param httpClient The service used to make http request
   */
  constructor(private httpClient: HttpClient) { }

  /***
   * This will broadcast the Locations changes to all component listening to it
   */
  emitLocations(response: { status: number, message: string, locations: Location[] }) {
    this.locationsSubject.next(response);
  }

  /***
   * This will broadcast the Location change to all component listening to it
   */
  emitLocation(response: { status: number, message: string, location: Location }) {
    this.locationSubject.next(response);
  }

  /***
   * This will broadcast the Location change to all component listening to it
   */
  emitSelectedLocation(location: Location) {
    this.selectedLocationSubject.next(location);
  }

  /***
   * This will gather all the Locations
   */
  getAllLocations() {
    this.httpClient.get(
      this.rootURL + '/GetLocations',
      {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }).subscribe((response: { status: number, message: string, locations: Location[] }) => {
        // Success
        this.locations = response.locations;
        this.emitLocations(response);
      },
      (err) => {
        // Error
        console.log('Erreur ! : ', err);
      }
    );
  }

  /***
   * This will gather specified Location given its id
   * @param locationId Id of the location to return
   */
  getLocation(locationId: number) {

    this.httpClient.get(
      this.rootURL + '/GetLocations' ,
      {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }).subscribe((response: { status: number, message: string, locations: Location[] }) => {
        // Success
        const locations = response.locations.filter((elem: Location) => {
          console.log(elem);
          return elem.id === locationId;
        });
        this.emitLocation({
          status: response.status,
          message: response.message,
          location: locations[0]
        });
      },
      (err) => {
        // Error
        console.log('Erreur ! : ', err);
      }
    );
  }

  /***
   * This will create a new Location
   * @param name Name of the new location
   * @param description Description of the new location
   * @param azureId The azureId of this location
   * @param visualModel Boolean saying if it used the visualModel or not
   */
  createLocation(name: string, description: string, azureId: string, visualModel: boolean): Promise<{status: number, message: string}>{

    return new Promise<{status: number, message: string}> ((resolve, reject) => {
      this.httpClient.post(
        this.rootURL + '/CreateLocation',
        {
          data: {
            name,
            description,
            azureId,
            selected: false,
            visualModel
          },
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        })
        .toPromise().then((response: { status: number, message: string}) => {
          // Success
          resolve(response);
        },
        err => {
          // Error
          reject({errorMessage: err});
        }
      );
    });
  }

  /***
   * This update given Location
   * @param id Id of the location to update
   * @param name Updated name of the location
   * @param description Updated description of the location
   * @param azureId The azureId of this location
   * @param visualModel Boolean saying if it used the visualModel or not
   */
  updateLocation(id: number, name: string,
                 description: string,
                 azureId: string,
                 visualModel: boolean): Promise<{status: number, message: string}>{

    return new Promise<{status: number, message: string}> ((resolve, reject) => {
      this.httpClient.post(
        this.rootURL + '/SetLocation',
        {
          data: {
            id,
            name,
            description,
            azureId,
            selected: false,
            visualModel
          },
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        })
        .toPromise().then((response: { status: number, message: string}) => {
          // Success
          resolve(response);
        },
        err => {
          // Error
          reject({errorMessage: err});
        }
      );
    });
  }

  /***
   * This method is used to update the selected location on the server
   * @param id The id of the newly selected location
   */
  selectLocation(id: number) {
    this.selectedLocation = id;
    // return new Promise<{status: number, message: string}> ((resolve, reject) => {
    this.httpClient.post(
      this.rootURL + '/SelectLocation',
      {
        data: {
          id
        },
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      })
      .subscribe();
    // });
  }
}
