import {Component, OnDestroy, OnInit} from '@angular/core';
import {TaskService} from '../service/task-service';
import {Task} from '../model/task';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {SubTaskService} from '../service/sub-task.service';
import {SubTask} from '../model/sub-task';
import {TaskObject} from '../model/taskObject';
import {TaskObjectService} from '../service/task-object.service';
import {Action} from '../model/action';
import {ActionService} from '../service/action.service';
import {LocationService} from '../service/location.service';
import {Location} from '../model/location';
import {AuthService} from '../service/auth-service';
import {QuestionnaireModel} from '../model/questionnaire-model';
import {QuestionnaireService} from '../service/questionnaire-service';
import {DataService} from '../service/data.service';


@Component({
  selector: 'app-toggle-menu',
  templateUrl: './toggle-menu.component.html',
  styleUrls: ['./toggle-menu.component.css']
})
/***
 * This component is used to describe the side menu you can toggle
 */
export class ToggleMenuComponent implements OnInit, OnDestroy {
  // the selected task
  selectedTask: string;
  // the selected object
  selectedObject: number;
  // the selected action
  selectedAction: number;
  // the select subtask
  selectedSubTask: number;
  // the selected location
  selectedLocation: number;
  // the selected questionnaire
  selectedQuestionnaire: number;
  // the selected data
  selectedData: number;
  // the full list of available tasks
  tasks: Task[];
  // the full list of available subtasks
  subTasks: SubTask[];
  // the full list of available actions
  actions: Action[];
  // the full list of available task objects
  taskObjects: TaskObject[];
  // List of available locations
  locations: Location[];
  // List of available locations
  questionnaires: QuestionnaireModel[];
  // list of available data
  availableData: { id: number, name: string }[];
  // Subscription to the list of tasks
  taskSubscription = new Subscription();
  // Subscription to the list of subtasks
  subTaskSubscription = new Subscription();
  // Subscription to the list of actions
  actionsSubscription = new Subscription();
  // Subscription to the list of task objects
  taskObjectsSubscription = new Subscription();
  // subscription to locations
  locationsSubscription = new Subscription();
  // subscription to questionnaire
  questionnairesSubscription = new Subscription();
  // subscription to data
  availableDataSubscription = new Subscription();

  /***
   * constructor
   * @param taskService Service used to interact with task on server
   * @param subTaskService Service used to interact with subtask and links on server
   * @param taskObjectService Service used to interact with subtask and objects on server
   * @param actionService Service used to interact with subtask and actions on server
   * @param locationService Service used to interact with locations on server
   * @param authService Service used to login/logout from server
   * @param questionnaireService Service used to get info about questionnaires from server
   * @param router Service used to navigate to a path
   */
  constructor(private taskService: TaskService,
              private subTaskService: SubTaskService,
              private taskObjectService: TaskObjectService,
              private actionService: ActionService,
              private locationService: LocationService,
              private authService: AuthService,
              private questionnaireService: QuestionnaireService,
              private dataService: DataService,
              private router: Router) {

    this.questionnairesSubscription = this.questionnaireService.questionnairesSubject
      .subscribe((questionnaires: QuestionnaireModel[]) => {
      this.questionnaires = questionnaires;
    });
    this.taskSubscription = this.taskService.tasksSubject.subscribe((tasks: Task[]) => {
      this.tasks = tasks;
      this.selectedTask = '-1';
    });

    this.subTaskSubscription = this.subTaskService.subTasksSubject.subscribe((subtasks: SubTask[]) => {
      this.subTasks = subtasks;
      this.selectedSubTask = -1;
    });

    this.taskObjectsSubscription = this.taskObjectService.allTaskObjectsSubject.
    subscribe((taskObjects: TaskObject[]) => {
      this.taskObjects = taskObjects;
      this.selectedObject = -1;
    });

    this.actionsSubscription = this.actionService.actionsSubject
      .subscribe((response: { status: number, message: string, actions: Action[] }) => {
      this.actions = response.actions;
      this.selectedAction = -1;
    });

    this.locationsSubscription = this.locationService.locationsSubject
      .subscribe((answer: { status: number, message: string, locations: Location[] }) => {
      this.locations = answer.locations;
    });

    this.availableDataSubscription = this.dataService.availableDataSubject.subscribe((availableData: {id: number, name: string}[]) => {
      this.availableData = availableData;
    });
  }

  ngOnInit() {
    this.taskService.getTasks();
    this.subTaskService.getAllSubTasks();
    this.taskObjectService.getAllTaskObjects();
    this.actionService.getAllActions();
    this.questionnaireService.getQuestionnaires();
    this.dataService.getAvailableData(undefined);
  }

  /***
   * This this method will redirect to the selected task, to edit it.
   */
  editSelectedTask() {
    if (this.selectedTask === '-1')
    {
      return;
    }
    this.router.navigate(['/TaskEditor/' + this.selectedTask]);
  }

  /***
   * This this method will redirect to the selected subtask, to edit it.
   */
  editSelectedSubTask() {
    if (this.selectedSubTask === -1)
    {
      return;
    }
    this.router.navigate(['/SubTaskEditor/' + this.selectedSubTask]);
  }

  /***
   * This this method will redirect to the training of the selected task.
   */
  followSelectedTask() {
    if (this.selectedTask === '-1')
    {
      return;
    }
    this.router.navigate(['/FollowTask/' + this.selectedTask]);
  }

  /***
   * This this method will redirect to the display of task object of given task.
   */
  getTaskObjectsOfSelectedTask() {
    if (this.selectedTask === '-1')
    {
      return;
    }
    this.router.navigate(['/TaskObjectsOfTask/' + this.selectedTask]);
  }

  /***
  * This this method will redirect to the display of task object of given object.
  */
  getTaskObjectsOfSelectedObject() {
    if (this.selectedObject === -1)
    {
      return;
    }
    this.router.navigate(['/TaskObjectsOfObject/' + this.selectedObject]);
  }

  /***
   * This return true if the taskObject contains sub objects
   * @param taskObject The task object to test
   */
  haveSubObjects(taskObject: TaskObject) {
    return taskObject.subObjects !== undefined && taskObject.subObjects.length > 0;
  }

  /***
   * This will redirect you to proper url when you click on Add/Remove task
   */
  addRemoveTaskObjectsToSelectedTask() {
    if (this.selectedTask === '-1')
    {
      return;
    }
    this.router.navigate(['/AddRemoveObjectToTask/' + this.selectedTask]);
  }

  /***
   * This will redirect you to proper url when you click on Add/Remove object
   */
  addRemoveTaskObjectsToSelectedObject() {
    if (this.selectedObject === -1)
    {
      return;
    }
    this.router.navigate(['/AddRemoveObjectToObject/' + this.selectedObject]);
  }

  /***
   * This will redirect you to proper url when you click on an object
   */
  editSelectedObject() {
    if (this.selectedObject === -1)
    {
      return;
    }
    this.router.navigate(['/TaskObjectEditor/' + this.selectedObject]);
  }

  /***
   * This will redirect you to proper url when you click on an action
   */
  editSelectedAction() {
    if (this.selectedAction === -1)
    {
      return;
    }
    this.router.navigate(['/ActionEditor/' + this.selectedAction]);
  }

  /***
   * This will redirect you to proper url when you click on an action
   */
  editSelectedLocation() {
    if (this.selectedLocation === -1)
    {
      return;
    }
    this.router.navigate(['/LocationEditor/' + this.selectedLocation]);
  }

  /***
   * Logout from server
   */
  logout() {
    this.authService.logout();
    // this.router.navigate(['/Home']);
  }

  /***
   * This will redirect to the questionnaire page
   */
  showQuestionnaire() {
    if (this.selectedLocation === -1)
    {
      return;
    }
    this.router.navigate(['/Questionnaire/' + this.selectedQuestionnaire]);
  }

  /***
   * This will redirect to the data page
   */
  showData() {
    if (this.selectedData === undefined)
    {
      return;
    }
    this.router.navigate(['/Data/' + this.selectedData]);
  }

  ngOnDestroy(): void {
    this.taskSubscription.unsubscribe();
    this.taskObjectsSubscription.unsubscribe();
    this.subTaskSubscription.unsubscribe();
    this.actionsSubscription.unsubscribe();
    this.availableDataSubscription.unsubscribe();
    this.locationsSubscription.unsubscribe();
    this.questionnairesSubscription.unsubscribe();
  }
}
