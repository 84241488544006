import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
/***
 * Service used to interact with data from server
 */
export class DataService {
  // the gathered data, which is a JSON object
  data: object;
  // List of available data
  availableData: {id: number, name: string}[];
  // Subject used to broadcast gathered data changes
  dataSubject = new Subject<object>();
  // Subject used to broadcast gathered data changes
  availableDataSubject = new Subject<{id: number, name: string}[]>();
  // root url
  rootURL = '/api';

  /***
   * Constructor
   * @param httpClient The http client used to reach the server.
   */
  constructor(private httpClient: HttpClient) {}

  /***
   * Broadcast data info
   */
  emitDataSubject() {
    this.dataSubject.next(this.data ? this.data : {});
  }

  /***
   * Broadcast data info
   */
  emitAvailableDataSubject() {
    this.availableDataSubject.next(this.availableData ? this.availableData : []);
  }

  /***
   * Will request the full list of available data then broadcast it. It will then call the callback if defined.
   * @param callback a callback
   */
  getAvailableData(callback) {
    this.httpClient.get(
      this.rootURL + '/GetDisplayableData',
      {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }).subscribe((response: { status: number, message: string, data: {id: number, name: string}[] }) => {
        // Success
        this.availableData = response.data;

        this.emitAvailableDataSubject();
        if (callback !== undefined) {
          callback();
        }
      },
      (err) => {
        // Error
        console.log('Erreur ! : ', err);
      }
    );
  }

  /***
   * This will return the specified data as a JSON object
   * @param name Name of the data to return
   */
  getDataWithName(name: string){
    this.httpClient.post(
      this.rootURL + '/GetDisplayableDataWithName',
      {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        },
        data: {
          name
        }
      }).subscribe((response: { status: number, message: string, data: object }) => {
        // Success
        this.data = response.data;

        this.emitDataSubject();
      },
      (err) => {
        // Error
        console.log('Erreur ! : ', err);
      }
    );
  }
}
