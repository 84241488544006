import {Component, OnDestroy, OnInit} from '@angular/core';
import {Action} from '../model/action';
import {Subscription} from 'rxjs';
import {ActionService} from '../service/action.service';
import {ActivatedRoute, Params} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StringService} from "../service/string.service";

@Component({
  selector: 'app-action-builder',
  templateUrl: './action-builder.component.html',
  styleUrls: ['./action-builder.component.css']
})
/***
 * Class used to describe the action builder component, used to create or edit actions.
 */
export class ActionBuilderComponent implements OnInit, OnDestroy {

  // current action
  action: Action;
  // subscription to action
  actionSubscription = new Subscription();
  // current action id
  actionId: number;
  // Form builder used to generate the reactive form
  actionBuilderForm: FormGroup;
  // Error message displayed when there is one, in a red box
  errorMessage: string;
  // Success message displayed when query return a message. In a blue box
  message: string;
  // Boolean used to know if the reactive form has been initialized or not.
  initialized: boolean;

  /***
   *  Constructor
   * @param formBuilder : form builder used to describe the task
   * @param actionService Service used to interact with DB action
   * @param route Service used to get route params.
   */
  constructor(private formBuilder: FormBuilder,
              private actionService: ActionService,
              private route: ActivatedRoute) {
    this.actionSubscription = this.actionService.actionSubject
      .subscribe((response: { status: number, message: string, action: Action }) => {
      // this.action = action;
      if (response.status === 200) {
        this.actionBuilderForm.setValue({
          id: response.action.id,
          name: response.action.name,
        });
      } else {
        this.errorMessage = response.message;
      }
    });

    this.route.params.subscribe((params: Params) => {
      if (params.actionId !== undefined) {
        this.actionId = params.actionId;

        this.actionService.getAction(this.actionId - 1);
      }
    });

  }

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Method that will initialize the form.
   */
  initForm() {
    this.actionBuilderForm = this.formBuilder.group(
      {
        id: [{value: '', disabled: this.actionId !== undefined}],
        name: ['', Validators.required]
      });

    this.initialized = true;
  }

  // convenience getter for easy access to form fields
  get controls() { return this.actionBuilderForm.controls; }

  /**
   * Method called when you create a subtask. It will send subtask properties to the server through the subTaskService.
   */
  createSubTask() {
    if (this.actionBuilderForm.invalid) {
      this.errorMessage = 'fields are not properly filled';
      return;
    }

    const id = this.actionBuilderForm.get('id').value;
    const name = StringService.removeQuote(this.actionBuilderForm.get('name').value);

    // create the task if in create mode
    if (this.actionId === undefined) {
      this.actionService.createAction(name).then((answer: {status: number, message: string}) => {
        if (answer.status === 200) {
          this.errorMessage = undefined;
          this.message = answer.message;
        }
        else {
          this.errorMessage = answer.message;
          this.message = undefined;
        }
        this.updateListOfActions();
      });
    } else {
      this.actionService.updateAction(id, name).then((answer: {status: number, message: string}) => {
        if (answer.status === 200) {
          this.errorMessage = undefined;
          this.message = answer.message;
        }
        else {
          this.errorMessage = answer.message;
          this.message = undefined;
        }
        this.updateListOfActions();
      });
    }
  }

  /***
   * Update the list of action everywhere
   */
  updateListOfActions() {
    this.actionService.getAllActions();
  }

  ngOnDestroy(): void {
    this.actionSubscription.unsubscribe();
  }
}
