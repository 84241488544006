import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {SubTask} from '../model/sub-task';
import {TaskLink} from '../model/task-link';

@Injectable({
  providedIn: 'root'
})
/***
 * Service used to interact with sub tasks and task link data from server.
 */
export class SubTaskService {
  // A map of task links for each task. The key is task id;
  taskLinks: Map<number, TaskLink[]>;
  // The subject to listen to get the  map of task links for each task
  taskLinksSubject = new Subject<Map<number, TaskLink[]>>();
  // The list of subtask
  subTasks: SubTask[];
  // The subject to listen to get the list of sub task
  subTasksSubject = new Subject<SubTask[]>();
  // the task link
  taskLink: TaskLink;
  // The subject to listen to get task link
  taskLinkSubject =  new Subject<TaskLink>();
  // The subject to listen to get the selected taskLink id
  selectedTaskLinkSubject = new Subject<number>();
  // Id of the selected taskLink
  selectedTaskLink : number;

  // root url
  rootURL = '/api';

  /***
   * Constructor
   * @param httpClient The service used to make http request
   */
  constructor(private httpClient: HttpClient) { }

  /***
   * This will broadcast the task links changes to all component listening to it
   */
  emitTaskLinks() {
    this.taskLinksSubject.next(this.taskLinks);
  }

  /***
   * This will broadcast the task link change to all component listening to it
   */
  emitTaskLink() {
    this.taskLinkSubject.next(this.taskLink);
  }

  /***
   * This will broadcast the sub tasks changes to all component listening to it
   */
  emitSubTasks() {
    this.subTasksSubject.next(this.subTasks);
  }


  /***
   * Broadcast that the selected id is the selected taskLink
   * @param selectedId
   */
  emitSelectedTaskLink(selectedId: number) {
    this.selectedTaskLink = selectedId;
    this.selectedTaskLinkSubject.next(selectedId);
  }

  /***
   * This will gather the sub tasks for a given task id.
   * @param taskId Id of the task which sub tasks should be gathered/
   */
  getSubTasks(taskId: number): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpClient.get(
        this.rootURL + '/GetSubTask/' + taskId,
        {
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }).toPromise().then((response: { status: number, message: string, subTasks: SubTask[] }) => {
          // Success
          resolve(response);
        },
        err => {
          // Error
          reject({errorMessage: err});
        }
      );
    });
  }

  /***
   * This will gather the task link for a given id
   * @param taskLinkId Id of the task link to get
   */
  getTaskLink(taskLinkId: number): Promise<{ status: number, message: string, tasksLink: TaskLink, errorMessage: string }> {

    return new Promise((resolve, reject) => {
      this.httpClient.get(
        this.rootURL + '/GetTaskLink/' + taskLinkId,
        {
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }).toPromise().then((response: { status: number, message: string, tasksLink: TaskLink, errorMessage: string }) => {
          // Success
          resolve(response);
        },
        err => {
          // Error
          reject({errorMessage: err});
        }
      );
    });
  }

  /***
   * This will gather all the subtasks
   */
  getAllSubTasks() {
    this.httpClient.get(
      this.rootURL + '/GetSubTasks',
      {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }).subscribe((response: { status: number, message: string, subTasks: SubTask[] }) => {
        // Success
        this.subTasks = response.subTasks;
        this.emitSubTasks();
      },
      (err) => {
        // Error
        console.log('Erreur ! : ', err);
      }
    );
  }

  /***
   * This will gather the sub tasks for a given id and update the list in the web-app
   * @param taskId Id of the task which links have to be gathered
   */
  GetTaskLinksOfTask(taskId: number): void {

    this.httpClient.get(
      this.rootURL  + '/GetTaskLinksOfTask/' + taskId,
      {
        headers: {
          'Content-type' : 'application/json',
          Accept: 'application/json'
        }}).subscribe(
      (response: { status: number, tasksLinks: TaskLink[], message: string}) => {

        const subTasks = new Array<TaskLink>();
        const taskLinks = response.tasksLinks.sort((e1: TaskLink, e2: TaskLink) => {
          return e1.id - e2.id;
        });
        if (this.taskLinks === undefined) {
          this.taskLinks = new Map<number, TaskLink[]>();
        }

        for (const taskLink of taskLinks) {
          subTasks.push(taskLink);
        }
        this.taskLinks.set(taskId, subTasks);

        this.emitTaskLinks();
        if (this.selectedTaskLink) {
          this.emitSelectedTaskLink(undefined);
        }
      },
      (error) => {
        console.log('Erreur ! : ', error);
      }
    );
  }

  /***
   * This will create a new sub task given an ID and a description
   * @param id id of the sub task. TODO: Check if can be removed
   * @param description Description of the task
   */
  createSubTask(id: number, description: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(
        this.rootURL  + '/createSubTask',
        {
          data: {
            id,
            description
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }})
        .toPromise()
        .then((response: any) => {
            // Success
            resolve({message: response.message, errorMessage: response.errorMessage});
          },
          err => {
            // Error
            reject({errorMessage: err});
          }
        );
    });
  }

  /***
   * This will update the sub task given its ID and description
   * @param id id of the sub task.
   * @param description Description of the task
   */
  updateSubTask(id: number, description: string): Promise<any> {

    return new Promise((resolve, reject) => {
      this.httpClient.post(
        this.rootURL  + '/updateSubTask',
        {
          data: {
            id,
            description
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }})
        .toPromise()
        .then((response: any) => {
            // Success
            resolve({message: response.message, errorMessage: response.errorMessage});
          },
          err => {
            // Error
            reject({errorMessage: err});
          }
        );
    });
  }

  /***
   * This will update task link with given information
   * @param id id of the task link to edit
   * @param currentTask new currentTask of the task link
   * @param currentSubTask new currentSubTask of the task link
   * @param nextTask1 new nextTask1 of the task link
   * @param nextTask2 new nextTask2 of the task link
   * @param nextTask3 new nextTask3 of the task link
   * @param nextTask4 new nextTask4 of the task link
   * @param nextTask5 new nextTask5 of the task link
   * @param condition1 new condition1 of the task link
   * @param condition2 new condition2 of the task link
   * @param condition3 new condition3 of the task link
   * @param condition4 new condition4 of the task link
   * @param condition5 new condition5 of the task link
   */
  updateTaskLink(id: number,
                 currentTask: number,
                 currentSubTask: number,
                 nextTask1: number,
                 nextTask2: number,
                 nextTask3: number,
                 nextTask4: number,
                 nextTask5: number,
                 condition1: string,
                 condition2: string,
                 condition3: string,
                 condition4: string,
                 condition5: string
                 ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient.post(
        this.rootURL  + '/updateTaskLink',
        {
          data: {
            id,
            currentTask,
            currentSubTask,
            nextTask1,
            nextTask2,
            nextTask3,
            nextTask4,
            nextTask5,
            condition1,
            condition2,
            condition3,
            condition4,
            condition5
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }})
        .toPromise()
        .then((response: any) => {
            // Success
            resolve({message: response.message, errorMessage: response.errorMessage});
          },
          err => {
            // Error
            reject({errorMessage: err});
          }
        );
    });
  }

  /***
   * This will create a new task link with given info
   * @param currentTask currentTask of the new task link
   * @param currentSubTask currentSubTask of new the task link
   * @param nextTask1 nextTask1 of the new task link
   * @param nextTask2 nextTask2 of the new task link
   * @param nextTask3 nextTask3 of the new task link
   * @param nextTask4 nextTask4 of the new task link
   * @param nextTask5 nextTask5 of the new task link
   * @param condition1 condition1 of the new task link
   * @param condition2 condition2 of the new task link
   * @param condition3 condition3 of the new task link
   * @param condition4 condition4 of the new task link
   * @param condition5 condition5 of the new task link
   */
  createTaskLink(currentTask: number,
                 currentSubTask: number,
                 nextTask1: number,
                 nextTask2: number,
                 nextTask3: number,
                 nextTask4: number,
                 nextTask5: number,
                 condition1: string,
                 condition2: string,
                 condition3: string,
                 condition4: string,
                 condition5: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.httpClient.post(
        this.rootURL  + '/createTaskLink',
        {
          data: {
            currentTask,
            currentSubTask,
            nextTask1,
            nextTask2,
            nextTask3,
            nextTask4,
            nextTask5,
            condition1,
            condition2,
            condition3,
            condition4,
            condition5
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }})
        .toPromise()
        .then((response: any) => {
            // Success
            resolve({message: response.message, errorMessage: response.errorMessage});
          },
          err => {
            // Error
            reject({errorMessage: err});
          }
        );
    });
  }

  /***
   * This method will ask the server to delete a sub task and all associated task links
   * @param id id of the sub task to delete.
   */
  deleteSubTask = function(id: number) : Promise<{message: string, errorMessage: string}>{
    return new Promise<{message: string, errorMessage: string}>((resolve, reject) => {

      this.httpClient.post(
        this.rootURL  + '/DeleteSubTask',
        {
          data: {
            id
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }})
        .toPromise()
        .then((response: any) => {
            // Success
            resolve({message: response.message, errorMessage: response.errorMessage});
          },
          err => {
            // Error
            reject({errorMessage: err});
          }
        );
    });
  }

  /***
   * This method will ask the server to delete a sub task and all associated task links
   * @param id id of the sub task to delete.
   */
  deleteTaskLink = function(id: number) : Promise<{message: string, errorMessage: string}>{
    return new Promise<{message: string, errorMessage: string}>((resolve, reject) => {

      this.httpClient.post(
        this.rootURL  + '/DeleteTaskLink',
        {
          data: {
            id
          },
          headers: {
            'Content-type' : 'application/json',
            Accept: 'application/json'
          }})
        .toPromise()
        .then((response: any) => {
            // Success
            resolve({message: response.message, errorMessage: response.errorMessage});
          },
          err => {
            // Error
            reject({errorMessage: err});
          }
        );
    });
  }
}
