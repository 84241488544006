import {Component, OnDestroy} from '@angular/core';
import {TaskService} from '../service/task-service';
import {Task} from '../model/task';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-tracker-sheet',
  templateUrl: './tracker-sheet.component.html',
  styleUrls: ['./tracker-sheet.component.css']
})
/***
 * Component used to describe a tracker sheet, which basically displays the full list of tasks.
 */
export class TrackerSheetComponent implements OnDestroy{

  // The task to describe
  tasks: Task[];

  // The task listener
  taskSubscription: Subscription;

  /***
   * Constructor
   * @param taskService Service used to interact with task on server
   */
  constructor(private taskService: TaskService) {
    this.tasks = new Array<Task>();

    this.taskSubscription = taskService.tasksSubject.subscribe((tasks) => {
      this.tasks = tasks;
    });

    taskService.getTasks();
  }

  ngOnDestroy(): void {
    this.taskSubscription.unsubscribe();
  }

}
