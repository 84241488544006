import {Component, OnDestroy, OnInit} from '@angular/core';
import {TaskObject} from '../model/taskObject';
import {Subscription} from 'rxjs';
import {TaskObjectService} from '../service/task-object.service';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-task-objects-of-task-displayer',
  templateUrl: './task-objects-of-task-displayer.component.html',
  styleUrls: ['./task-objects-of-task-displayer.component.css']
})
/***
 * This is used to display the list of task object via the task object component. It can displays 2 things:
 * - It will displays the list of task object of a task if a taskID is provided (url)
 * - It will displays the list of task object of a object if a taskObjectID is provided (url)
 */
export class TaskObjectsOfTaskDisplayerComponent implements OnInit, OnDestroy {

  // The task object to describe
  taskObjects: TaskObject[];
  // the id of taskObject
  taskObjectId: number;
  // The task object to describe
  taskObjectsSubscription = new Subscription();

  /***
   * Constructor
   * @param taskObjectService Service used ot interact with task object from server.
   * @param route Service used to get route parameters.
   */
  constructor(private taskObjectService: TaskObjectService, private route: ActivatedRoute) { }

  /***
   * Called one time only, once the page is initialized
   */
  ngOnInit(): void {
    // check route params.
    this.route.params.subscribe( (params: Params) => {
      this.taskObjectId = params.taskId;

      // listen to list of object to display
      this.taskObjectsSubscription = this.taskObjectService.taskObjectsSubject.subscribe((taskObjects: TaskObject[]) => {
        console.log('taskObjects');
        console.log(taskObjects);
        this.taskObjects = taskObjects;
      });

      // If a task id is provided, then displays objects of task
      if (this.taskObjectId !== undefined) {
        this.taskObjectService.getTaskObjectsOfTask(this.taskObjectId);
      }
      // If a task object id is provided, then displays objects of object
      else if (params.taskObjectId !== undefined) {
        this.taskObjectId = params.taskObjectId;
        this.taskObjectService.getTaskObjectsOfObject(this.taskObjectId);
      }
    });
  }

  ngOnDestroy(): void {
    this.taskObjectsSubscription.unsubscribe();
  }
}
