import {Component, EventEmitter, Input, Output} from '@angular/core'


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
/***
 * This component is used to display a confirmation dialog, with title, text and action needs to be provided.
 */
export class ConfirmationDialogComponent  {

  // This will be used as title
  @Input()
  modalTitle: string;
  // this will be used as modal content
  @Input()
  modalText: string;
  // This is the displayed text on the action button
  @Input()
  modalActionText: string;

  // This is an event emitted when clicking on action button
  @Output()
  onModalClick : EventEmitter<any> = new EventEmitter();

  constructor() {}

  /***
   * This method will ensure an event is sent when clicking on the action button
   */
  actionButtonClicked() : void {
    this.onModalClick.emit([this.modalText])
  }
}
