import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location} from '../model/location';
import {ActivatedRoute, Params} from '@angular/router';
import {LocationService} from '../service/location.service';
import {StringService} from '../service/string.service';

@Component({
  selector: 'app-location-builder',
  templateUrl: './location-builder.component.html',
  styleUrls: ['./location-builder.component.css']
})
/***
 * This component describe the page used to edit or create a new location.
 */
export class LocationBuilderComponent implements OnInit, OnDestroy {
// current location
  location: Location;
  // subscription to location
  locationSubscription = new Subscription();
  // current location id
  locationId: number;
  // Form builder used to generate the reactive form
  locationBuilderForm: FormGroup;
  // Error message displayed when there is one, in a red box
  errorMessage: string;
  // Success message displayed when query return a message. In a blue box
  message: string;
  // Boolean used to know if the reactive form has been initialized or not.
  initialized: boolean;

  /***
   * Construtor
   * @param formBuilder : form builder used to describe the task
   * @param locationService Service used to interact with DB location
   * @param route Service used to get route params.
   */
  constructor(private formBuilder: FormBuilder,
              private locationService: LocationService,
              private route: ActivatedRoute) {

    this.locationSubscription = this.locationService.locationSubject
      .subscribe((response: { status: number, message: string, location: Location }) => {

        // this.action = action;
        if (response.status === 200 && response.location !== undefined) {
          this.locationBuilderForm.setValue({
            id: response.location.id,
            name: response.location.name,
            description: response.location.description,
            azureId: response.location.azureId,
            visualModel: response.location.visualModel,
          });

          this.errorMessage = undefined;
        }
        else if (response.location === undefined) {
          this.errorMessage = 'Pas de lieu pour l\'id: ' + this.locationId;
        }
        else {
          this.errorMessage = response.message;
        }
      });

    this.route.params.subscribe((params: Params) => {
      const id = parseInt(params.locationId, 10);
      if (params.locationId !== undefined && !isNaN(id) ) {
        this.locationId = id;

        this.locationService.getLocation(this.locationId);
      }
    });
  }

  ngOnInit(): void {
     this.initForm();
  }

  /**
   * Method that will initialize the form.
   */
  initForm() {
    this.locationBuilderForm = this.formBuilder.group(
      {
        id: [{value: '', disabled: this.locationId !== undefined}],
        name: ['', Validators.required],
        description: ['', Validators.required],
        azureId: [''],
        visualModel: [false, Validators.required]
      });

    this.initialized = true;
  }

  // convenience getter for easy access to form fields
  get controls() { return this.locationBuilderForm.controls; }

  /**
   * Method called when you create a subtask. It will send subtask properties to the server through the subTaskService.
   */
  createLocation() {
    if (this.locationBuilderForm.invalid) {
      this.errorMessage = 'fields are not properly filled';
      return;
    }

    const id = this.locationBuilderForm.get('id').value;
    const name = StringService.removeQuote(this.locationBuilderForm.get('name').value);
    const description = StringService.removeQuote(this.locationBuilderForm.get('description').value);
    const azureId = StringService.removeQuote(this.locationBuilderForm.get('azureId').value);
    const visualModel = this.locationBuilderForm.get('visualModel').value;

    // create the task if in create mode
    if (this.locationId === undefined) {
      this.locationService.createLocation(name, description, azureId,  visualModel)
        .then((answer: {status: number, message: string, errorMessage: string}) => {
          this.handleResult(answer);
      });
    }
    else {
      this.locationService.updateLocation(id, name, description, azureId, visualModel)
        .then((answer: {status: number, message: string, errorMessage: string}) => {
          this.handleResult(answer);
      });
    }
  }

  /***
   * Update the list of action everywhere
   */
  updateListOfLocation() {
    this.locationService.getAllLocations();
  }

  /***
   * This will handle what to do with the result of request. Displays the message when it's fine, or the error
   * when it's not. Then it will update the list of locations
   * @param answer the answer to handle
   */
  handleResult(answer: {status: number, message: string, errorMessage: string}) {
    if (answer.status === 200 && this.errorMessage === undefined) {
      this.errorMessage = undefined;
      this.message = answer.message;
    }
    else {
      this.errorMessage = answer.errorMessage;
      this.message = undefined;
    }
    this.updateListOfLocation();
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }
}
