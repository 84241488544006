import {Injectable} from '@angular/core';
import {QuestionnaireModel} from '../model/questionnaire-model';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {QuestionnaireDataModel} from '../model/questionnaire-data-model';
import {DataToSendModel} from '../model/data-to-send-model';
import {DataService} from './data.service';

@Injectable({
  providedIn: 'root'
})
/***
 * Service used to interact with server questionnaire. It will let you gather the list of questionnaire and send
 * questionnaire values to server
 */
export class QuestionnaireService {
  // internal list of available questionnaire
  questionnaires: QuestionnaireModel[];
  // internal selected questionnaire
  questionnaire: QuestionnaireModel;
  // Subject used to broadcast list of questionnaires changes
  questionnairesSubject = new Subject<QuestionnaireModel[]>();
  // Subject used to broadcast selected questionnaire changes
  questionnaireSubject = new Subject<QuestionnaireModel>();
  // root url
  rootURL = '/api';

  /***
   * Constructor
   * @param dataService The service to interact with data
   * @param httpClient The http client used to reach the server.
   */
  constructor(private dataService: DataService, private httpClient: HttpClient) { }

  /***
   * This is used to let the subject emits update of user list.
   */
  emitQuestionnairesSubject(): void{
    this.questionnairesSubject.next(this.questionnaires ? this.questionnaires.slice() : []);
  }

  /***
   * This is used to let the subject emits update of user list.
   */
  emitQuestionnaireSubject(): void{
    this.questionnaireSubject.next(this.questionnaire ? this.questionnaire : undefined);
  }

  /***
   * Method used to sort questionnaire by ID. It will compare 2 questionnaire ids.
   * @param questionnaire1 first questionnaire to compare
   * @param questionnaire2 second questionnaire to compare
   */
  sortQuestionnaire(questionnaire1: QuestionnaireModel, questionnaire2: QuestionnaireModel): number{
    return questionnaire1.id - questionnaire2.id;
  }


  /***
   * This will gather the list of questionnaire then broadcast it
   */
  getQuestionnaires(): void {
    this.httpClient.get(
      this.rootURL + '/GetQuestionnaires',
      {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }).subscribe((response: { status: number, message: string, questionnaires: QuestionnaireModel[] }) => {
        // console.log('was: ' + this.questionnaires.length);
        // Success
        this.questionnaires = response.questionnaires.sort(this.sortQuestionnaire);

        this.emitQuestionnairesSubject();
      },
      (err) => {
        // Error
        console.log('Erreur ! : ', err);
      }
    );
  }

  /***
   * This will gather the questionnaire with given id then broadcast it
   * @param questionnaireId Id of the questionnaire to broadcast
   */
  getQuestionnaire(questionnaireId: number): void {

    this.httpClient.get(
      this.rootURL + '/GetQuestionnaire/' + questionnaireId,
      {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      }).subscribe((response: { status: number, message: string, questionnaire: QuestionnaireModel }) => {
        // Success
        this.questionnaire = response.questionnaire;
        this.emitQuestionnaireSubject();
      },
      (err) => {
        // Error
        console.log('Erreur ! : ', err);
      }
    );
  }

  /***
   * This method will send given questionnaire data to server.
   * @param questionnaireData The questionnaire data to store
   */
  sendQuestionnaireData(questionnaireData: QuestionnaireDataModel): Promise<{status: number, message: string, errorMessage: string}>{
    return new Promise<{status: number, message: string, errorMessage: string}>((resolve, reject) => {
      this.requestThenCallback(0, questionnaireData.answers.length, new Date().getTime(), questionnaireData, resolve, reject);
    });
  }

  /***
   * This method is used to allow sending multiple row of data to the server. It will send one row, wait for the result,
   * then send the next one, and repeat till the end of data to send. Then it will return a server answer containing a
   * message or an error message
   * @param index index of current element in the list of questionnaire data
   * @param maxIndex length of the questionnaire data
   * @param time time when the first data was sent. Used to name the DB
   * @param questionnaireData the full list of data to send
   * @param resolve the callback when all went fine
   * @param reject the callback when something wrong happened.
   */
  requestThenCallback(index, maxIndex, time, questionnaireData, resolve, reject): void {
    const questionToSend: DataToSendModel =  {
      answers: undefined,
      questionId: undefined,
      questionLabel: undefined,
      questionnaireDescription: undefined,
      questionnaireId: undefined,
      questionnaireName: undefined,
    };

    questionToSend.questionnaireId = questionnaireData.id;
    questionToSend.questionnaireDescription = questionnaireData.description;
    questionToSend.questionnaireName = questionnaireData.name;
    questionToSend.answers = questionnaireData.answers[index].answers.toString();
    questionToSend.questionId = questionnaireData.answers[index].questionId;
    questionToSend.questionLabel = questionnaireData.answers[index].questionLabel;

    this.httpClient.post(
      this.rootURL + '/UploadTrainingData',
      {
        data: {
          data: questionToSend,
          when: questionToSend.questionnaireName + '_' + time,
        },
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
        }
      })
      .toPromise().then((response: { status: number, message: string, errorMessage: string}) => {
        // Success
        index++;
        if (index === maxIndex)
        {
          this.dataService.getAvailableData(() => {
            resolve(response);
          });
        }
        else {
          this.requestThenCallback(index, maxIndex, time, questionnaireData, resolve, reject);
        }
      },
      err => {
        // Error
        reject({errorMessage: err});
      });
  }
}
