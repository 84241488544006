import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DataService} from '../service/data.service';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-data-displayer',
  templateUrl: './data-displayer.component.html',
  styleUrls: ['./data-displayer.component.css']
})
/***
 * This component describe the data displayer page. It display data in a table, with some information displayed on top
 * of the table
 */
export class DataDisplayerComponent implements OnInit, OnDestroy {
  // id of the data to load
  dataId: number;
  // list of data, as multiple JSON object
  data: object[];
  // list of the headers of current data
  dataHeaders: string[];
  // list of the filtered headers. It let the component only displayed some information in the table. The others outside
  filteredHeaders: string[];
  // subscription to data
  dataSubscription = new Subscription();
  // list containing the names of the available data
  availableData: {id: number, name: string}[];
  // subscription to the list of available data
  availableDataSubscription = new Subscription();
  // header used for the sort
  sortHeader: string;
  // used to know if the sort should be reversed
  reverseMode: boolean;
  // text used to filter
  filterText: string;

  /***
   * Constructor
   * @param dataService Service used to interact with data on server
   * @param route Service used to read route parameters
   */
  constructor(private dataService: DataService, private route: ActivatedRoute) {
    this.dataSubscription = this.dataService.dataSubject.subscribe((data: object[]) => {
      this.data = data;

      if (this.data && this.data.length > 0) {
        this.dataHeaders = Object.keys(this.data[0]);

        this.filteredHeaders = this.dataHeaders.filter((header: string) => {
          return header !== 'questionnaireDescription' &&
            header !== 'questionnaireId' &&
            header !== 'id' &&
            header !== 'questionnaireName';
        }).reverse();
      }
    });

    this.availableDataSubscription = this.dataService.availableDataSubject.subscribe((availableData: {id: number, name: string}[]) => {
      this.availableData = availableData;


      if (availableData !== undefined && availableData.length > 0) {

        const dataNames = this.availableData.filter((data: {id: number, name: string}) => {
          return data.id.toString() === this.dataId.toString();
        });

        if (dataNames.length > 0) {
          const name = dataNames[0].name;
          this.dataService.getDataWithName(name);
        }
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe( (params: Params) => {
      this.dataId = params.dataId;
      this.dataService.getAvailableData(undefined);
    });
  }

  /***
   * This method is used to sort elements depending on the value of header.
   * @param header header to use for the sort
   */
  sortBy(header: string) {
    this.reverseMode = this.sortHeader !== undefined && header === this.sortHeader && !this.reverseMode;

    this.data.sort((e1: object, e2: object) => {
      if(this.reverseMode) {
        return e2[header].localeCompare(e1[header]);
      }
      else {
        return e1[header].localeCompare(e2[header]);
      }
    });
    this.sortHeader = header;

  }

  ngOnDestroy(): void {
    this.availableDataSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
  }
}
