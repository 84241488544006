import {Component, OnDestroy, OnInit} from '@angular/core';
import {Task} from '../model/task';
import {TaskService} from '../service/task-service';
import {SubTaskService} from '../service/sub-task.service';
import {SubTask} from '../model/sub-task';
import {Subscription} from 'rxjs';
import * as $ from 'jquery';

@Component({
  selector: 'app-task-link-editor',
  templateUrl: './task-link-editor.component.html',
  styleUrls: ['./task-link-editor.component.css']
})
/***
 * Component used to let edit a task link.
 */
export class TaskLinkEditorComponent implements OnInit, OnDestroy {
  // The task id of the link to edit
  selectedTask: string;
  // the task link Id
  selectedTaskLink: number;
  // The full list of tasks
  tasks: Task[];
  // the full list of subtasks
  subTasks: SubTask[];
  // Subscription to the list of tasks
  taskSubscription = new Subscription();
  // Subscription to the list of subtasks
  subTaskSubscription = new Subscription();
  selectedTaskLinkSubscription = new Subscription();

  /***
   * constructor
   * @param taskService Service used to interact with task on server
   * @param subTaskService Service used to interact with subtask and links on server
   */
  constructor(private taskService: TaskService, private subTaskService: SubTaskService) {

    this.taskSubscription = this.taskService.tasksSubject.subscribe((tasks: Task[]) => {
      this.tasks = tasks;
      this.selectedTask = tasks[0].id;

      this.selectATask(this.selectedTask);

    });

    this.subTaskSubscription = this.subTaskService.taskLinksSubject.subscribe((subtasks: Map<number, SubTask[]>) => {
      this.subTasks = subtasks.get(parseInt(this.selectedTask, 10));
      // this.selectedTaskLink = this.subTasks[0] ? this.subTasks[0].id : undefined;
      //
      // this.selectATaskLink(this.selectedTaskLink);
    });

    this.selectedTaskLinkSubscription = this.subTaskService.selectedTaskLinkSubject.subscribe((selectedId: number) => {
      this.selectedTaskLink = selectedId;
      this.showSelection(selectedId);
    });
  }

  ngOnInit() {
    this.taskService.getTasks();
  }

  /***
   * Used when you click on a task in the select to set it as the selected one.
   * @param task id of the task to select
   */
  selectATask(task: string) {
    this.subTaskService.GetTaskLinksOfTask(parseInt(task, 10));
  }

  /***
   * Used when you click on a link in the list of links to set it as the selected one.
   * @param link id of the link to select
   */
  selectATaskLink(link: number) {

    this.showSelection(link);
    this.selectedTaskLink = link;

    this.subTaskService.emitSelectedTaskLink(link);
  }

  /***
   * Highlight selected one
   * @param link
   */
  showSelection(link: number) {
    // alert(link)
    const selectedLink =  $('#subTask_card_' + this.selectedTaskLink);
    const currentLink =  $('#subTask_card_' + link);

    selectedLink.css('background-color', 'white');
    selectedLink.hover(() => {
        selectedLink.css('background-color', 'aliceblue');
      },
      () => {
        selectedLink.css('background-color', 'white');
      });
    currentLink.css('background-color', 'lavender');
    currentLink.hover(() => {
        currentLink.css('background-color', 'lavender');
      },
      () => {
        currentLink.css('background-color', 'lavender');
      });

  }

  ngOnDestroy(): void {
    this.selectedTaskLinkSubscription.unsubscribe();
    this.subTaskSubscription.unsubscribe();
    this.taskSubscription.unsubscribe();
  }
}
