import {Component, OnDestroy} from '@angular/core';
import construct = Reflect.construct;
import {Subscription} from 'rxjs';
import {AuthService} from './service/auth-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
/***
 * This is the main component
 */
export class AppComponent implements OnDestroy{
  title = 'ceratizit-web-app';
  expanded = true;
  // subscription to login
  isLoggedSubscription = new Subscription();
  // know if user is logged or not
  isLogged: boolean;

  constructor(private authService: AuthService){
    this.isLoggedSubscription = this.authService.isLoggedSubject.subscribe((auth) => {
      this.isLogged = auth;
    });
  }

  expandCollapse() {
    this.expanded = !this.expanded;
  }

  ngOnDestroy(): void {
    this.isLoggedSubscription.unsubscribe();
  }

}

